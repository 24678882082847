import { EMPTY_FIELD } from 'constants/emptyField';

import { useEffect } from 'react';

import { OrdersListLocalDatagridCopyButton } from 'components/Orders/OrdersListNew/OrdersListLocalDatagrid/OrdersListLocalDatagridCopyButton';
import { useLocalDatagrid } from 'hooks/useLocalDatagrid';
import { useLocalDatagridPagination } from 'hooks/useLocalDatagridPagination';
import { usePermissions } from 'hooks/usePermissions';
import { NumberField, RaRecord, useListContext, useRemoveFromStore } from 'react-admin';
import { Box } from 'shared/mui/Box';
import { TablePagination } from 'shared/mui/Table';
import { Typography } from 'shared/mui/Typography';
import { DatagridActions } from 'shared/react-admin/Datagrids/DatagridActions/DatagridActions';
import { DatagridActionsShowButton } from 'shared/react-admin/Datagrids/DatagridActions/DatagridActionsShowButton/DatagridActionsShowButton';
import {
  ChipField,
  CopyToClipboardLinkField,
  DateTimeField,
  FunctionField,
  OrderStatusChipField,
  TextField,
} from 'shared/react-admin/Fields';
import { CopyToClipboardField } from 'shared/react-admin/Fields/CopyToClipboardField/CopyToClipboardField';
import { ReferenceField, ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { appColors } from 'themes/variables';
import { Currency, Method, Order, OrderDispute } from 'types';
import { getId } from 'utils';

import {
  ImageFieldStyled,
  StyledDatagridConfigurableWithStickyActions,
} from './OrdersListLocalDatagrid.styled';
import { getRowStyle } from './utils/getRowStyle';
import { OrdersListBulkActions } from '../OrdersListBulkAction';

type Props = {
  orders: Order[];
  total: number;
  isLoading: boolean;
  setSelectedOrder: (order: Order) => void;
  setIsOpenDispute: (open: boolean) => void;
  setSelectedDispute: (dispute: OrderDispute | undefined) => void;
  timezone: string;
};

export const OrdersListLocalDatagrid: React.FC<Props> = ({
  total,
  isLoading,
  orders = [],
  setSelectedOrder,
  setIsOpenDispute,
  setSelectedDispute,
  timezone,
}) => {
  const { hasResourceAccess } = usePermissions();
  const { resource } = useListContext();
  const remove = useRemoveFromStore();

  const { sortedData, sort, setSort } = useLocalDatagrid({
    data: orders,
    defaultSort: {
      field: 'createdAt',
      order: 'ASC',
    },
  });

  const { dataPerPage, ...paginationProps } = useLocalDatagridPagination<Order>({
    data: sortedData,
    defaultPage: 0,
    defaultRowsPerPage: 10,
  });

  useEffect(() => {
    return () => {
      remove(`${resource}.selectedIds`);
      remove(`${resource}.listParams`);
    };
  }, []);

  return (
    <>
      <StyledDatagridConfigurableWithStickyActions
        bulkActionButtons={
          hasResourceAccess('order_disputes') && (
            <OrdersListBulkActions
              setIsOpen={setIsOpenDispute}
              setSelectedDispute={setSelectedDispute}
              setSelectedOrder={setSelectedOrder}
            />
          )
        }
        data={dataPerPage}
        isLoading={isLoading}
        rowSx={getRowStyle}
        setSort={setSort}
        sort={sort}
        sx={{ width: '100%' }}
      >
        <CopyToClipboardLinkField
          getLink={(record: RaRecord) => `/orders/${encodeURIComponent(record.id)}/show`}
          isTrimValue
          label="Kubera ID"
          source="originId"
          target="_blank"
        />
        <CopyToClipboardField
          getValue={getId}
          isTrimValue
          label="Мерчант ID"
          source="merchantReference"
        />
        <CopyToClipboardField
          getValue={getId}
          isTrimValue
          label="Провайдер ID"
          source="providerReferenceId"
        />
        <DateTimeField label="Время создания" source="createdAt" timezone={timezone} />
        <NumberField label="Сумма" options={{ minimumFractionDigits: 2 }} source="amount" />
        {hasResourceAccess('currencies') && (
          <ReferenceOneField
            label="Валюта"
            link={false}
            reference="currencies"
            sortable={false}
            source="currency"
            target="id"
          >
            <FunctionField
              render={(record: Currency) => record?.alphaCode}
              sx={{
                color: appColors.primary.main,
                fontWeight: 700,
              }}
            />
          </ReferenceOneField>
        )}
        <ReferenceField label="Направление" reference="directions" source="direction">
          <ChipField source="description" />
        </ReferenceField>
        <ReferenceOneField
          emptyText={EMPTY_FIELD}
          label="Метод"
          link={false}
          reference="methods"
          resource="methods"
          sortable={false}
          source="method"
          target="id"
        >
          <FunctionField
            render={(record: Method) => (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {hasResourceAccess('method_logos') && (
                  <ReferenceOneField reference="method_logos" source="logo" target="id">
                    <ImageFieldStyled source="filePath" />
                  </ReferenceOneField>
                )}
                <Typography variant="body2">{record?.name}</Typography>
              </Box>
            )}
          />
        </ReferenceOneField>
        <ReferenceField
          label="Статус"
          reference="new/order_statuses"
          sortable={false}
          source="status"
        >
          <OrderStatusChipField source="description" />
        </ReferenceField>
        {hasResourceAccess('merchants') && (
          <ReferenceOneField
            label="Мерчант"
            link={false}
            reference="merchants"
            sortable={false}
            source="merchant"
            target="id"
          >
            <ChipField source="name" />
          </ReferenceOneField>
        )}
        <ReferenceField label="Клиент" link={false} reference="customers" source="customer">
          <CopyToClipboardField getValue={getId} source="customerId" underline={false} />
        </ReferenceField>
        <CopyToClipboardField label="Данные клиента" source="customerData" underline={false} />
        {hasResourceAccess('providers') && (
          <ReferenceOneField
            label="Провайдер"
            reference="providers"
            sortBy="provider.name"
            source="provider"
            target="id"
          >
            <TextField source="name" />
          </ReferenceOneField>
        )}
        {hasResourceAccess('gateways') && (
          <ReferenceOneField
            label="Шлюз"
            link={false}
            reference="gateways"
            sortable={false}
            source="gateway"
            target="id"
          >
            <TextField source="name" />
          </ReferenceOneField>
        )}
        <TextField emptyText={EMPTY_FIELD} label="Причина отклонения" source="externalError" />
        <DatagridActions>
          <OrdersListLocalDatagridCopyButton timezone={timezone} />
          <DatagridActionsShowButton target="_blank" />
        </DatagridActions>
      </StyledDatagridConfigurableWithStickyActions>
      {total > 0 && <TablePagination count={total} {...paginationProps} />}
    </>
  );
};
