import { FilterChipType } from 'constants/filterChipType';

import { useEffect, useMemo } from 'react';

import { OrdersReportDateFilter } from 'components/Finance/OrdersReport/OrdersReportDateFilter';
import { OrdersReportFilterGroupById } from 'components/Finance/OrdersReport/OrdersReportFilterGroupById';
import ru from 'date-fns/locale/ru';
import { useFilterChips } from 'hooks/useFilterChips';
import { useFilters } from 'hooks/useFilters';
import { useFirstRender } from 'hooks/useFirstRender';
import { useListContext } from 'react-admin';
import 'react-datepicker/dist/react-datepicker.css';
import { registerLocale } from 'react-datepicker';
import { FormProvider } from 'react-hook-form';
import {
  DateRangePickerValue,
  DateRangeTimezoneValue,
} from 'shared/mui/DatePicker/DateTimeRangeTimezonePicker/DateTimeRangeTimezonePicker';
import { Divider } from 'shared/mui/Divider/Divider';
import { FilterApplyButton } from 'shared/mui/Filter/FilterButtons/FilterApplyButton/FilterApplyButton';
import { FilterResetButton } from 'shared/mui/Filter/FilterButtons/FilterResetButton/FilterResetButton';
import { FilterCollapsible } from 'shared/mui/Filter/FilterCollapsible/FilterCollapsible';
import { FilterRow } from 'shared/mui/Filter/FilterRow/FilterRow';
import { AutocompleteInputShrink, EnumAutocompleteInputShrink } from 'shared/react-admin/Inputs';
import { AutocompleteMultiselectInputShrink } from 'shared/react-admin/Inputs/AutocompleteMultiselectInputShrink/AutocompleteMultiselectInputShrink';
import { ReferenceArrayInput } from 'shared/react-admin/ReferenceInputs';
import { appColors } from 'themes/variables';
import { getDateRangeDefaultValues, getDateRangeFilter, getIdsArray } from 'utils';

registerLocale('ru', ru);

type Props = {
  alert?: JSX.Element | undefined;
};

export type OrdersReportFilterFormValues = {
  id: string;
  merchantReference: string;
  providerReferenceId: string;
  transaction: string;
  company: string;
  merchant: string;
  provider: string;
  merchantAccount: string[];
  gateway: string;
  createdAt: {
    gte: number;
    lte: number;
  };
  transactions: {
    createdAt: {
      gte: Date;
      lte: Date;
    };
  };
  appliedDate: [DateRangePickerValue, DateRangePickerValue, DateRangeTimezoneValue];
  transactionsAppliedDate: [DateRangePickerValue, DateRangePickerValue, DateRangeTimezoneValue];
  direction: string;
  timezone: string;
};

export const OrdersReportFilter: React.FC<Props> = ({ alert }) => {
  const { displayedFilters, filterValues, setFilters } = useListContext();
  const { isFirstRender } = useFirstRender();

  const {
    resetFilter,
    resetFilterArrayById,
    resetFilterBySource,
    form,
    form: { watch },
  } = useFilters(
    {
      id: null,
      merchantReference: null,
      providerReferenceId: null,
      company: '',
      provider: '',
      transaction: '',
      merchant: '',
      merchantAccount: [],
      gateway: [],
      direction: '',
      createdAt: {
        gte: '',
        lte: '',
      },
      transactions: {
        createdAt: {
          gte: '',
          lte: '',
        },
      },
    },
    {
      ...filterValues,
      transactions: {
        createdAt: getDateRangeDefaultValues(filterValues?.transactions?.createdAt),
      },
      createdAt: getDateRangeDefaultValues(filterValues?.createdAt),
    },
  );

  const onSubmit = (values: OrdersReportFilterFormValues) => {
    const idsArray = getIdsArray(values.id);
    const merchantReferenceIdsArray = getIdsArray(values.merchantReference);
    const providerReferenceIdsArray = getIdsArray(values.providerReferenceId);
    form.setValue('id', idsArray);
    form.setValue('merchantReference', merchantReferenceIdsArray);
    form.setValue('providerReferenceId', providerReferenceIdsArray);

    setFilters(
      {
        ...values,
        id: idsArray,
        merchantReference: merchantReferenceIdsArray,
        providerReferenceId: providerReferenceIdsArray,
        createdAt: getDateRangeFilter(values?.createdAt),
        transactions: {
          createdAt: getDateRangeFilter(values?.transactions?.createdAt),
        },
      },
      displayedFilters,
    );
  };

  const { filterChipValues } = useFilterChips({
    filters: [
      { source: 'id', label: 'Kubera ID', type: FilterChipType.String },
      { source: 'merchantReference', label: 'Мерчант ID', type: FilterChipType.String },
      { source: 'providerReferenceId', label: 'Провайдер ID', type: FilterChipType.String },
      { source: 'transaction', label: 'ID транзакции', type: FilterChipType.String },
      { source: 'createdAt.gte', label: 'Создан от', type: FilterChipType.DateInterval },
      { source: 'createdAt.lte', label: 'Создан до', type: FilterChipType.DateInterval },
      {
        source: 'transactions.createdAt.gte',
        label: 'Исполнен от',
        type: FilterChipType.DateInterval,
      },
      {
        source: 'transactions.createdAt.lte',
        label: 'Исполнен до',
        type: FilterChipType.DateInterval,
      },
      { source: 'direction', label: 'Направление', type: FilterChipType.Direction },
      { source: 'company', label: 'Компания', type: FilterChipType.Id },
      { source: 'merchant', label: 'Мерчант', type: FilterChipType.Id },
      { source: 'provider', label: 'Провайдер', type: FilterChipType.Id },
      { source: 'gateway', label: 'Шлюз', type: FilterChipType.IdArray },
      { source: 'merchantAccount', label: 'Счет мерчанта', type: FilterChipType.IdArray },
    ],
    appliedFilters: filterValues,
  });

  const formValues = watch();

  const [company, merchant, provider] = watch(['company', 'merchant', 'provider']);

  const isIdFiltersDisabled = useMemo(() => {
    const { id, merchantReference, providerReferenceId } = formValues;
    const isFormClear = Object.values(formValues).every(
      (formValue) => formValue instanceof Date || !formValue || !(formValue as []).length,
    );
    return (
      form.formState.isDirty && !isFormClear && !id && !merchantReference && !providerReferenceId
    );
  }, [formValues]);

  const isRestFiltersDisabled = useMemo(() => {
    const { id, merchantReference, providerReferenceId } = formValues;
    return !!(form.formState.isDirty && (id || merchantReference || providerReferenceId));
  }, [formValues]);

  useEffect(() => {
    if (isRestFiltersDisabled && !isFirstRender) {
      form.setValue('createdAt.gte', '');
      form.setValue('createdAt.lte', '');
      form.setValue('transactions.createdAt.gte', '');
      form.setValue('transactions.createdAt.lte', '');
    }
  }, [isIdFiltersDisabled, isRestFiltersDisabled]);

  useEffect(() => {
    if (!isFirstRender) {
      form.setValue('merchant', []);
      form.setValue('provider', []);
      form.setValue('merchantAccount', []);
    }
  }, [company]);

  useEffect(() => {
    if (!isFirstRender) {
      form.setValue('provider', []);
      form.setValue('merchantAccount', []);
    }
  }, [merchant]);

  useEffect(() => {
    if (!isFirstRender) {
      form.setValue('merchantAccount', []);
      form.setValue('gateway', []);
    }
  }, [provider]);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FilterCollapsible
          actions={[
            <FilterApplyButton key="apply-button" type="submit" />,
            <FilterResetButton key="reset-button" onClick={resetFilter} />,
          ]}
          alert={alert}
          filterChipValues={filterChipValues}
          resetFilterArrayById={resetFilterArrayById}
          resetFilterBySource={resetFilterBySource}
        >
          <OrdersReportFilterGroupById
            isIdFiltersDisabled={isIdFiltersDisabled}
            resetFilters={resetFilter}
          />
          <Divider
            orientation="horizontal"
            sx={{ marginTop: 2, marginBottom: 2, borderColor: appColors.divider }}
          />
          <OrdersReportDateFilter form={form} isRestFiltersDisabled={isRestFiltersDisabled} />
          <Divider
            orientation="horizontal"
            sx={{ marginTop: 2, marginBottom: 2, borderColor: appColors.divider }}
          />
          <FilterRow sx={{ mb: 2 }}>
            <EnumAutocompleteInputShrink
              disabled={isRestFiltersDisabled}
              filterToQuery={(searchText: string) => ({ description: searchText })}
              fullWidth
              helperText={false}
              label="Направление"
              name="direction"
              optionText="description"
              optionValue="value"
              resource="directions"
              size="small"
              source="direction"
              variant="outlined"
            />
            <ReferenceArrayInput
              filter={{ exists: { merchants: true }, archive: false }}
              isFilter={true}
              name="company"
              perPage={1000}
              reference="companies"
              resource="companies"
              source="company"
            >
              <AutocompleteInputShrink
                disabled={isRestFiltersDisabled}
                filterToQuery={(searchText: string) => ({ name: searchText })}
                helperText={false}
                label="Компания"
                name="company"
                optionText="name"
                variant="outlined"
              />
            </ReferenceArrayInput>
            <ReferenceArrayInput
              enableGetChoices={() => !!formValues?.company?.length}
              filter={{
                companies: formValues.company,
                exists: { merchantAccounts: true },
                archive: false,
              }}
              isFilter={true}
              name="merchant"
              perPage={100}
              reference="merchants"
              resource="orders"
              source="merchant"
            >
              <AutocompleteInputShrink
                disabled={!formValues?.company?.length}
                filterToQuery={(searchText: string) => ({ name: searchText })}
                helperText={false}
                label="Мерчант"
                name="merchant"
                optionText="name"
                variant="outlined"
              />
            </ReferenceArrayInput>
            <ReferenceArrayInput
              enableGetChoices={() => !!formValues?.merchant?.length}
              filter={{ merchantAccounts: { merchants: formValues.merchant } }}
              isFilter={true}
              name="provider"
              perPage={100}
              reference="providers"
              resource="providers"
              source="provider"
            >
              <AutocompleteInputShrink
                disabled={!formValues?.merchant?.length}
                helperText={false}
                label="Провайдер"
                name="provider"
                optionText="name"
                variant="outlined"
              />
            </ReferenceArrayInput>
          </FilterRow>
          <FilterRow>
            <ReferenceArrayInput
              enableGetChoices={() =>
                !!formValues?.merchant?.length && !!formValues?.provider?.length
              }
              filter={{
                merchantAccounts: { merchants: formValues?.merchant },
                provider: formValues?.provider,
              }}
              isFilter={true}
              name="gateway"
              perPage={100}
              reference="gateways"
              resource="orders"
              source="gateway"
            >
              <AutocompleteMultiselectInputShrink
                disabled={!formValues?.provider?.length}
                helperText={false}
                label="Шлюз"
                name="gateway"
                optionText="name"
              />
            </ReferenceArrayInput>
            <ReferenceArrayInput
              enableGetChoices={() =>
                !!formValues?.merchant?.length && !!formValues?.provider?.length
              }
              filter={{
                merchants: formValues?.merchant,
                provider: formValues?.provider,
              }}
              isFilter={true}
              name="merchantAccount"
              perPage={1000}
              reference="merchant_accounts"
              resource="orders"
              source="merchantAccount"
            >
              <AutocompleteMultiselectInputShrink
                disabled={!formValues?.provider?.length}
                helperText={false}
                label="Счет мерчанта"
                name="merchantAccount"
                optionText="name"
              />
            </ReferenceArrayInput>
          </FilterRow>
        </FilterCollapsible>
      </form>
    </FormProvider>
  );
};
