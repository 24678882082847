import {
  FilterAutocompleteChip,
  FilterMultiselectChip,
  FilterTextChip,
} from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChips';
import { AutocompleteInputShrink, AutocompleteMultiselectInput } from 'shared/react-admin/Inputs';
import { AutocompleteMultiselectInputShrink } from 'shared/react-admin/Inputs/AutocompleteMultiselectInputShrink/AutocompleteMultiselectInputShrink';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';
import { ReferenceArrayInput, ReferenceInput } from 'shared/react-admin/ReferenceInputs';

import { Currency } from '../../../../types';

export const CommissionsListFilterChips: React.FC = () => {
  return (
    <>
      <FilterTextChip label="Название" name="name">
        <TextInputShrink
          fullWidth={true}
          helperText={false}
          key="name"
          label="Название"
          resettable
          source="name"
          variant="outlined"
        />
      </FilterTextChip>
      <FilterMultiselectChip
        label="Статус"
        limitTags={1}
        name="status"
        optionText="description"
        resource="commission_statuses"
      >
        <ReferenceArrayInput perPage={100} reference="commission_statuses" source="status">
          <AutocompleteMultiselectInput
            InputLabelProps={{ shrink: true }}
            clearOnBlur={false}
            filterToQuery={(searchText: string) => ({ name: searchText })}
            helperText={false}
            label="Статус"
            limitTags={1}
            name="status"
            optionText="description"
          />
        </ReferenceArrayInput>
      </FilterMultiselectChip>
      <FilterAutocompleteChip label="Мерчант" name="merchant" resource="merchants">
        <ReferenceInput perPage={100} reference="merchants" source="merchant">
          <AutocompleteInputShrink
            filterToQuery={(searchText: string) => ({ name: searchText })}
            fullWidth
            helperText={false}
            label="Мерчант"
            optionText="name"
            variant="outlined"
          />
        </ReferenceInput>
      </FilterAutocompleteChip>
      <FilterMultiselectChip label="Провайдер" limitTags={1} name="provider" resource="providers">
        <ReferenceArrayInput perPage={100} reference="providers" source="provider">
          <AutocompleteMultiselectInput
            InputLabelProps={{ shrink: true }}
            clearOnBlur={false}
            filterToQuery={(searchText: string) => ({ name: searchText })}
            helperText={false}
            label="Провайдер"
            limitTags={1}
            name="provider"
            optionText="name"
          />
        </ReferenceArrayInput>
      </FilterMultiselectChip>
      <FilterMultiselectChip label="Валюта" limitTags={1} name="currency" resource="currencies">
        <ReferenceArrayInput perPage={50} reference="currencies" source="currency">
          <AutocompleteMultiselectInputShrink
            filterToQuery={(searchText: string) => ({ name: searchText })}
            helperText={false}
            label="Валюта"
            limitTags={1}
            name="currency"
            optionText="name"
            renderChipLabel={(record: Currency) => `${record?.alphaCode}, ${record?.name}`}
          />
        </ReferenceArrayInput>
      </FilterMultiselectChip>
      <FilterMultiselectChip
        label="Типы транзакций"
        limitTags={1}
        name="transactionTypes"
        resource="transaction_types"
      >
        <ReferenceArrayInput perPage={100} reference="transaction_types" source="transactionTypes">
          <AutocompleteMultiselectInputShrink
            helperText={false}
            label="Типы транзакций"
            name="transactionTypes"
            optionText="name"
          />
        </ReferenceArrayInput>
      </FilterMultiselectChip>
      <FilterTextChip label="ID комиссии" name="id">
        <TextInputShrink
          fullWidth={true}
          helperText={false}
          label="ID комиссии"
          resettable
          source="id"
          variant="outlined"
        />
      </FilterTextChip>
    </>
  );
};
