import { ConversionRateTypes } from 'constants/conversionTypes';
import {
  EXPORT_FORMAT_NAMES,
  ExportFormats,
  ExportJobTypes,
  ExportTransportTypes,
  ReportNames,
} from 'constants/exportFormats';

import React, { useState } from 'react';

import { useFlag } from '@unleash/proxy-client-react';
import { getUserInfo } from 'config/requests';
import { differenceInDays, millisecondsToSeconds } from 'date-fns';
import { useReportExport } from 'hooks/queries/useReportExport';
import { ListContextProvider, useList, useNotify } from 'react-admin';
import { FormProvider } from 'react-hook-form';
import { useQuery } from 'react-query';
import { ExportFormatsButton } from 'shared/mui/Buttons/ExportFormatsButton/ExportFormatsButton';
import { DEFAULT_TIMEZONE } from 'shared/mui/DatePicker/config/defaultValues';
import { DateRangePickerValue } from 'shared/mui/DatePicker/DateRangePicker/DateRangePicker';
import { DateRangeTimezoneValue } from 'shared/mui/DatePicker/DateTimeRangeTimezonePicker/DateTimeRangeTimezonePicker';
import { NotifyDialog } from 'shared/mui/Dialogs';
import { FilterContextProvider } from 'shared/mui/NewFilter/context/filterContext';
import {
  FilterApplyButton,
  FilterButton,
  FilterClearButton,
  FilterResetButton,
} from 'shared/mui/NewFilter/FilterButtons';
import { FilterChipsToolbar } from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChipsToolbar';
import {
  FilterDrawer,
  FilterDrawerActionsButtons,
  FilterDrawerContent,
} from 'shared/mui/NewFilter/FilterDrawer';
import { FilterToolbar } from 'shared/mui/NewFilter/FilterToolbar/FilterToolbar';
import { useFilters } from 'shared/mui/NewFilter/hooks/useFilters';
import { ReportExportDialogMessage } from 'shared/widgets/ReportExport/ReportExportDialogMessage';
import { ExportFormat } from 'types';
import { cleanEmpty } from 'utils/cleanEmpty';

import { BalanceReportFilter } from './BalanceReportFilter';
import { BalanceReportListFilterChips } from './BalanceReportListFilterChips';
import { BalanceReportNewFilter } from './BalanceReportNewFilter';
import { BalanceReportDatagrid } from './components/BalanceReportDatagrid/BalanceReportDatagrid';
import { useBalanceReportList } from './hooks/useBalanceReportList';
import { AppListContextProviderCard } from '../../Common/Card';
import { AppPanelHeader, AppPanelHeaderToolbar } from '../../Common/PanelHeader';

export type BalanceReportFilterValues = {
  currency?: string | null;
  conversionRateType?: ConversionRateTypes | undefined;
  periodFrom?: number | null;
  periodTo?: number | null;
  companies?: string[] | [];
  merchants?: string[] | [];
  providers?: string[] | [];
  merchantAccounts?: string[] | [];
  exportType?: ExportFormat;
  appliedDate?: [DateRangePickerValue, DateRangePickerValue, DateRangeTimezoneValue];
  timezone?: string;
};

const DEFAULT_BALANCE_REPORT_FILTER_VALUES = {
  conversionRateType: null,
  currency: null,
  companies: [],
  merchants: [],
  providers: [],
  merchantAccounts: [],
  appliedDate: [null, null, null],
};

export const BalanceReportList: React.FC = () => {
  const { form, onSubmit, onReset, appliedFilters, setAppliedFilter, openDrawer, toggleDrawer } =
    useFilters<any>({
      defaultValues: DEFAULT_BALANCE_REPORT_FILTER_VALUES,
      resetValues: DEFAULT_BALANCE_REPORT_FILTER_VALUES,
      mode: 'all',
    });

  const isNewBalanceFiltersEnabled = useFlag('wppa-6931-new-balance-report-filter');

  const [filters, setFilters] = useState<BalanceReportFilterValues>({});
  const { balanceList, isLoading } = useBalanceReportList(
    isNewBalanceFiltersEnabled
      ? {
          ...appliedFilters,
          periodFrom: millisecondsToSeconds(appliedFilters?.appliedDate[0]),
          periodTo: millisecondsToSeconds(appliedFilters?.appliedDate[1]),
          timezone: appliedFilters?.appliedDate[2],
        }
      : filters,
  );
  const [isOpenNotifyDialog, setIsOpenNotifyDialog] = useState(false);
  const [isSendReportOnEmail, setIsSendReportOnEmail] = useState(false);
  const [exportFormat, setExportFormat] = useState<ExportFormat>(ExportFormats.Excel);
  const { data: user } = useQuery('me', getUserInfo);
  const notify = useNotify();

  const listContext = useList({
    data: balanceList?.items || [],
  });

  const isListFilterExist =
    Object.keys(cleanEmpty(isNewBalanceFiltersEnabled ? appliedFilters : filters)).length > 0;

  const { mutate: exportReportMutate, isLoading: isExportLoading } = useReportExport({
    filters: isNewBalanceFiltersEnabled
      ? {
          ...appliedFilters,
          periodFrom: millisecondsToSeconds(appliedFilters.appliedDate[0]),
          periodTo: millisecondsToSeconds(appliedFilters.appliedDate[1]),
          timezone: appliedFilters.appliedDate[2] || DEFAULT_TIMEZONE,
          exportType: exportFormat,
          jobType: ExportJobTypes.Balance,
          ...(isSendReportOnEmail && { notificationTransport: [ExportTransportTypes.Email] }),
        }
      : {
          ...filters,
          exportType: exportFormat,
          jobType: ExportJobTypes.Balance,
          ...(isSendReportOnEmail && { notificationTransport: [ExportTransportTypes.Email] }),
        },
    preferenceKey: ReportNames.Balance,
    onSuccess: () => {
      notify(
        `Файл экспорта отчета по балансам формируется ${isSendReportOnEmail ? 'и будет отправлен вам на почту' : ''}`,
        {
          type: 'info',
        },
      );
      setIsOpenNotifyDialog(false);
    },
    onError: (error) => {
      notify(error.data?.errors[0]?.title, { type: 'error' });
    },
  });

  function onGenerateClick(filter: BalanceReportFilterValues) {
    setFilters(filter);
  }

  const [showAlert, setShowAlert] = useState(true);

  function submitHandler(values: any) {
    form.clearErrors();
    setShowAlert(true);

    // TODO: убрать после удаления флага
    if (isNewBalanceFiltersEnabled) {
      const [from, to] = form.getValues('appliedDate');

      const intervalInDays = differenceInDays(to, from);
      if (intervalInDays > 1825) {
        form.setError('periodError', {
          type: 'custom',
          message: 'Период формирования отчета не должен превышать 1825 календарных дней!',
        });

        return;
      }
    }

    onSubmit(values);
  }

  return (
    <ListContextProvider value={listContext}>
      <AppListContextProviderCard>
        <AppPanelHeader>
          <AppPanelHeaderToolbar
            actions={[
              <ExportFormatsButton
                exportFormat={exportFormat}
                key="export-button"
                onChangeFormat={setExportFormat}
                onClick={() => setIsOpenNotifyDialog(true)}
              />,
            ]}
            titleText="Отчёт о балансах"
            tooltipText="Отчет о балансах - это сводный отчет по движению финансовых средств на балансе “Счетов мерчантов” за запрошенный период времени. Обратите внимание, что данный отчет не отражает фактическое состояние баланса."
          />
        </AppPanelHeader>
        {isNewBalanceFiltersEnabled ? (
          <>
            <FilterToolbar
              leftActionsSlot={[
                <FilterButton key="filter" onClick={toggleDrawer(true)} />,
                <FilterClearButton
                  key="clear"
                  onClick={() => {
                    onReset();
                  }}
                  visible={isListFilterExist}
                />,
              ]}
            />
            <FilterDrawer onClose={toggleDrawer(false)} open={openDrawer}>
              <FormProvider {...form}>
                <form onSubmit={form.handleSubmit(submitHandler)}>
                  <FilterDrawerContent>
                    <BalanceReportNewFilter setShowAlert={setShowAlert} showAlert={showAlert} />
                  </FilterDrawerContent>
                  <FilterDrawerActionsButtons>
                    <FilterApplyButton type="submit" />
                    <FilterResetButton label="Очистить" onReset={onReset} />
                  </FilterDrawerActionsButtons>
                </form>
              </FormProvider>
            </FilterDrawer>
            <FilterChipsToolbar appliedFilters={appliedFilters}>
              <FilterContextProvider
                appliedFilters={appliedFilters}
                setAppliedFilter={setAppliedFilter}
              >
                <FormProvider {...form}>
                  <BalanceReportListFilterChips />
                </FormProvider>
              </FilterContextProvider>
            </FilterChipsToolbar>
          </>
        ) : (
          <BalanceReportFilter
            filters={filters}
            onGenerateClick={onGenerateClick}
            setFilters={setFilters}
          />
        )}
        <BalanceReportDatagrid filters={isNewBalanceFiltersEnabled ? appliedFilters : filters} />
      </AppListContextProviderCard>
      <NotifyDialog
        isLoading={isExportLoading || isLoading}
        message={
          <ReportExportDialogMessage
            exportFormat={EXPORT_FORMAT_NAMES[exportFormat]}
            isSendReportOnEmail={isSendReportOnEmail}
            onChange={() => setIsSendReportOnEmail((prev) => !prev)}
            userEmail={user?.corporateEmail || user?.email || 'почта неизвестна'}
          />
        }
        onClose={() => setIsOpenNotifyDialog(false)}
        onSubmit={exportReportMutate}
        open={isOpenNotifyDialog}
        title="Экспорт отчета"
      />
    </ListContextProvider>
  );
};
