import { ComponentType, ReactElement, useContext, useMemo } from 'react';

import { Loading, ResourceProps } from 'react-admin';

import { NotFound } from '../components/NotFound';
import { FINANCE_ROLES } from '../constants';
import { PAYMENT_ROLES, Role } from '../constants/roles';
import { APIContext } from '../context/apiContext';
import { Permission, Permissions } from '../types';

function getResourceFromPath(path: string): string {
  //обрезаем /admin/ из названия ресурса
  return path.slice(path.indexOf('/', 1) + 1);
}

function getCurrentPath(permissions: Permissions, name: string): string | undefined {
  return Object.keys(permissions).find((path) => getResourceFromPath(path) === name);
}

function checkUserHasOneOfRoles(userRoles: Role[], allowedRoles: Role[]) {
  return userRoles?.some((role) => allowedRoles.includes(role));
}

type ExtendedResourceProps = ResourceProps & {
  history?: ComponentType<any> | ReactElement;
  historyRecord?: ComponentType<any> | ReactElement;
  delete?: ComponentType<any> | ReactElement;
};

type ReturnUsePermissions = {
  getResourceFromPath: (path: string) => string;
  getResourcePropsByPermissions: (props: ExtendedResourceProps) => ExtendedResourceProps;
  hasResourceAccess: (resource: string) => boolean;
  hasResourcePermissions: (resource: string, permissions: Permission[]) => boolean;
  roles: Role[];
  permissions: Permissions;
};

export function usePermissions(): ReturnUsePermissions {
  const { user } = useContext(APIContext);

  const userRoles = useMemo(() => user.roles, [user]);

  const userPermissions = useMemo<Permissions>(() => {
    if (!checkUserHasOneOfRoles(userRoles, [Role.Admin, Role.Tech])) {
      delete user.permissions['/admin/users'];
    }

    return {
      ...user.permissions,
      ...(checkUserHasOneOfRoles(userRoles, [...FINANCE_ROLES, Role.Admin, Role.Tech]) && {
        '/admin/orders_report': [Permission.Full, Permission.Update],
      }),
      ...(checkUserHasOneOfRoles(userRoles, [...FINANCE_ROLES, Role.Tech, Role.Admin]) && {
        '/admin/providers_report': [Permission.Full],
      }),
      ...(checkUserHasOneOfRoles(userRoles, [...FINANCE_ROLES, Role.Tech, Role.Admin]) && {
        '/admin/cumulative_report': [Permission.Full],
      }),
      ...(checkUserHasOneOfRoles(userRoles, [
        ...FINANCE_ROLES,
        ...PAYMENT_ROLES,
        Role.Tech,
        Role.Admin,
      ]) && {
        '/admin/order_adjustments_report': [Permission.Full],
      }),
    };
  }, [user]);

  function hasResourceAccess(resource: string): boolean {
    const allAvailableResources = Object.keys(userPermissions).reduce((resources, currentPath) => {
      return [...resources, getResourceFromPath(currentPath)];
    }, [] as string[]);

    return allAvailableResources.includes(resource);
  }

  function hasResourcePermissions(resource: string, permissions: Permission[]): boolean {
    const currentPath = getCurrentPath(userPermissions, resource);

    if (!currentPath) {
      return false;
    }

    const currentPathPermissions = userPermissions[currentPath];
    return permissions.some((permission) => currentPathPermissions.includes(permission));
  }

  function getResourcePropsByPermissions(props: ExtendedResourceProps): ExtendedResourceProps {
    const currentPath = getCurrentPath(userPermissions, props.name);

    if (!currentPath) {
      return {
        name: props.name,
        create: Loading,
        edit: Loading,
        list: Loading,
        show: Loading,
        history: Loading,
        historyRecord: Loading,
      };
    }

    const currentPathPermissions = userPermissions[currentPath];

    if (currentPathPermissions.includes(Permission.Full)) {
      return props;
    }

    const isShowAccess =
      currentPathPermissions.includes(Permission.Read) ||
      currentPathPermissions.includes(Permission.Partial);

    return {
      ...props,
      create: currentPathPermissions.includes(Permission.Create) ? props.create : NotFound,
      edit: currentPathPermissions.includes(Permission.Update) ? props.edit : NotFound,
      show: isShowAccess ? props.show : NotFound,
      history: isShowAccess ? props.history : NotFound,
      historyRecord: isShowAccess ? props.historyRecord : NotFound,
      list: currentPathPermissions.includes(Permission.Read) ? props.list : NotFound,
    };
  }

  return {
    getResourceFromPath,
    getResourcePropsByPermissions,
    hasResourceAccess,
    hasResourcePermissions,
    roles: userRoles,
    permissions: userPermissions,
  };
}
