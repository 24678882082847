import { utcToZonedTime } from 'date-fns-tz';
import { DateObject } from 'react-multi-date-picker';
import { DEFAULT_TIMEZONE } from 'shared/mui/DatePicker/config/defaultValues';

export function fromTimestampToDateObject(
  timestamp: number | null,
  timezone?: string | null,
): DateObject | null {
  return timestamp ? new DateObject(utcToZonedTime(timestamp, timezone || DEFAULT_TIMEZONE)) : null;
}
