import { useFormContext } from 'react-hook-form';
import { Button, CancelButton } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { DialogProps } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Typography } from 'shared/mui/Typography';
import { getId } from 'utils';
import { cleanEmpty } from 'utils/cleanEmpty';

import { StyledBox, StyledDialog } from './OrderAdjustmentsSubmitDialog.styled';
import { OrderAdjustmentsTypes } from '../../../constants/orderAdjustmentTypes';
import { useCreateOrderAdjustments } from '../hooks/useCreateOrderAdjustments';
import { OrderAdjustmentsFormValues } from '../hooks/useOrderAdjustmentsForm';

type Props = DialogProps & { onSubmit: VoidFunction };

export const OrderAdjustmentsSubmitDialog = ({ open, onClose, onSubmit }: Props) => {
  const { getValues, handleSubmit } = useFormContext<OrderAdjustmentsFormValues>();
  const { createOrderAdjustments } = useCreateOrderAdjustments(onSubmit);

  const submitHandler = () => {
    const data = getValues();
    createOrderAdjustments.mutate({
      ...data,
      adjustmentType: getId(data.adjustmentType as string) as OrderAdjustmentsTypes,
      orderAdjustments: data.orderAdjustments.map((adjustment) => {
        return cleanEmpty({
          ...adjustment,
          orderId: getId(adjustment.orderId),
        });
      }),
    });
    onClose();
  };

  return (
    <StyledDialog maxWidth="md" onClose={onClose} open={open}>
      <form onSubmit={handleSubmit(submitHandler as any)}>
        <StyledBox>
          <CardHeader
            actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
            title="Отправить корректировку по заказам"
          />
          <CardContent>
            <Typography>
              После подтверждения, данные корректировок будут переданы в обработку.
            </Typography>
          </CardContent>
          <CardActionsButtons
            rightActionsSlot={[
              <Button key="save-button" label="Подтвердить" type="submit" variant="contained" />,
              <CancelButton key="cancel-button" onClick={onClose} variant="outlined" />,
            ]}
          />
        </StyledBox>
      </form>
    </StyledDialog>
  );
};
