import { ConfigurableDatagridColumn, useGetOne, useRecordContext, useStore } from 'react-admin';
import {
  Currency,
  Gateway,
  Merchant,
  MerchantCustomer,
  Method,
  OrderStatus,
  Provider,
} from 'types';

import { getDateAndTime } from '../utils/getDateAndTime';

export const useGetOrderCopyData = (timezone: string) => {
  const record = useRecordContext();
  const [availableColumns] = useStore<ConfigurableDatagridColumn[]>(
    'preferences.orders.datagrid.availableColumns',
    [],
  );

  const { data: currency } = useGetOne<Currency>(
    'currencies',
    { id: record?.currency as string },
    { enabled: !!record },
  );
  const { data: direction } = useGetOne(
    'directions',
    { id: record?.direction },
    { enabled: !!record },
  );
  const { data: method } = useGetOne<Method>(
    'methods',
    { id: record?.method },
    { enabled: !!record },
  );
  const { data: status } = useGetOne<OrderStatus>(
    'new/order_statuses',
    { id: record?.status },
    { enabled: !!record },
  );
  const { data: merchant } = useGetOne<Merchant>(
    'merchants',
    { id: record?.merchant },
    { enabled: !!record },
  );
  const { data: provider } = useGetOne<Provider>(
    'providers',
    { id: record?.provider },
    { enabled: !!record },
  );
  const { data: gateway } = useGetOne<Gateway>(
    'gateways',
    { id: record?.gateway },
    { enabled: !!record },
  );
  const { data: customer } = useGetOne<MerchantCustomer>(
    'customers',
    { id: record?.customer },
    { enabled: !!record },
  );

  const valueHandlers: Record<string, string | null | undefined> = {
    createdAt: getDateAndTime(record?.createdAt, timezone),
    currency: `${currency?.alphaCode}, ${currency?.name}`,
    direction: direction?.description,
    method: method?.name,
    status: status?.description,
    gateway: gateway?.name,
    provider: provider?.name,
    merchant: merchant?.name,
    customer: customer?.customerId,
  };

  const copyData = record
    ? availableColumns
        .filter(({ source }) => !!source)
        .map(({ source, label }) => {
          return valueHandlers[source as keyof typeof valueHandlers]
            ? `${label}: ${valueHandlers[source as keyof typeof valueHandlers]}`
            : `${label}: ${record[source as string]}`;
        })
        .join('\n')
    : '';

  return { copyData };
};
