import { styled } from '@mui/material/styles';
import { DatagridConfigurableWithStickyActions } from 'shared/react-admin/Datagrids/DatagridConfigurableWithStickyActions/DatagridConfigurableWithStickyActions';
import { ImageField } from 'shared/react-admin/Fields';
import { getColumnStyle } from 'utils';

export const StyledDatagridConfigurableWithStickyActions = styled(
  DatagridConfigurableWithStickyActions,
)({
  '& .column-originId': getColumnStyle(168),
  '& .column-merchantReference': getColumnStyle(168),
  '& .column-providerReferenceId': getColumnStyle(168),
  '& .column-amount': {
    ...getColumnStyle(132),
    textAlign: 'right',
  },
  '& .column-currency': getColumnStyle(88),
  '& .column-method': {
    minWidth: '168px',
  },
  '& .column-direction': getColumnStyle(136),
  '& .column-createdAt': getColumnStyle(120),
  '& .column-status': getColumnStyle(168),
  '& .column-merchant': {
    minWidth: '168px',
  },
  '& .column-customer': getColumnStyle(168),
  '& .column-customerData': {
    minWidth: '168px',
  },
  '& .column-provider': {
    minWidth: '168px',
  },
  '& .column-gateway': {
    minWidth: '168px',
  },
  '& .column-externalError': {
    minWidth: '240px',
  },
});

export const ImageFieldStyled = styled(ImageField)({
  '& .RaImageField-image': {
    margin: 0,
    marginRight: '2px',
    maxWidth: 24,
    maxHeight: 24,
    minWidth: 24,
    minHeight: 24,
    objectFit: 'contain',
  },
});
