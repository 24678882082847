import { Order } from 'types';

const RED_COLOR = '#FEEBEE';

const СolorConfig: Record<string, string> = {
  '/admin/new/order_statuses/failure': RED_COLOR,
  '/admin/new/order_statuses/expired': RED_COLOR,
  '/admin/new/order_statuses/cancelled': RED_COLOR,
};

export const getRowStyle = (record: Order) =>
  record?.status && СolorConfig[record.status]
    ? { background: СolorConfig[record.status] }
    : { background: 'white' };
