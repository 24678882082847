import { CommissionStatuses } from 'constants/commissionStatuses';

import { ChipFieldProps, useRecordContext } from 'react-admin';

import { ChipFieldStyled, StatusIconStyled } from './CommissionStatusChipField.styled';

export type CommissionStatusChipFieldProps = ChipFieldProps;

export const CommissionStatusChipField: React.FC<CommissionStatusChipFieldProps> = (props) => {
  const record = useRecordContext();

  return (
    <>
      <ChipFieldStyled
        icon={<StatusIconStyled status={record?.value as CommissionStatuses} />}
        {...props}
      />
      {/*<IconButtonStyled>*/}
      {/*  <ModeCommentOutlinedIcon />*/}
      {/*</IconButtonStyled>*/}
    </>
  );
};
