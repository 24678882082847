import { format, fromUnixTime } from 'date-fns';
import { RaRecord, useListContext } from 'react-admin';
import { Link } from 'react-router-dom';
import { IconButton } from 'shared/mui/IconButtons';
import { Stack } from 'shared/mui/Stack/Stack';
import { FunctionField } from 'shared/react-admin/Fields';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';
import { appColors } from 'themes/variables';
import { CumulativeReport } from 'types';

import { StyledCumulativeReportDatagrid } from './CumulativeReportDatagrid.styled';
import { EMPTY_FIELD } from '../../../../../constants/emptyField';
import { ReactComponent as ArrowDown } from '../../../../../images/arrowDown.svg';
import { ReactComponent as ArrowUp } from '../../../../../images/arrowUp.svg';
import { ReactComponent as CompletedReport } from '../../../../../images/completedReport.svg';
import { createFilterQueryString } from '../../../../../utils/createFilterQueryString';
import { CumulativeReportFilterValues } from '../../CumulativeReportList';
import { CumulativeReportEmptyAlert } from '../CumulativeReportAlerts/CumulativeReportEmptyAlert';
import { CumulativeReportPeriod } from '../CumulativeReportPeriods/CumulativeReportPeriod';

type CumulativeReportDatagridProps = {
  filters: CumulativeReportFilterValues;
};

export const CumulativeReportDatagrid: React.FC<CumulativeReportDatagridProps> = ({ filters }) => {
  const { data: cumulativeReport, isLoading } = useListContext();

  return (
    <>
      {!!cumulativeReport.length && !!Object.keys(filters).length && (
        <CumulativeReportPeriod filters={filters} />
      )}
      <StyledCumulativeReportDatagrid
        bulkActionButtons={false}
        data={cumulativeReport}
        empty={<CumulativeReportEmptyAlert />}
        isLoading={isLoading}
      >
        <FunctionField
          render={(record: CumulativeReport) => cumulativeReport.indexOf(record) + 1}
        />
        <FunctionField
          label="Период"
          render={(record: CumulativeReport) => (
            <Stack alignItems="start" direction="column" flexWrap="nowrap">
              <span>{format(fromUnixTime(record.periodFrom), 'dd/MM/yyyy')}</span>
              <span style={{ whiteSpace: 'nowrap', color: appColors.text.disabled }}>
                {format(fromUnixTime(record.periodFrom), 'HH:mm') +
                  ' — ' +
                  format(fromUnixTime(record.periodTo), 'HH:mm')}
              </span>
            </Stack>
          )}
        />
        <FunctionField
          label="Пополнения"
          render={(record: CumulativeReport) => {
            if (Number(record.paymentAmount) === 0) {
              return EMPTY_FIELD;
            }
            return (
              <span style={{ color: appColors.success.main }}>
                {`+${record.paymentAmount} ${record.currencyCode}`}
              </span>
            );
          }}
        />
        <FunctionField
          label="Выводы"
          render={(record: CumulativeReport) => {
            if (Number(record.payoutAmount) === 0) {
              return EMPTY_FIELD;
            }
            return (
              <span style={{ color: appColors.error.main }}>
                {`-${record.payoutAmount} ${record.currencyCode}`}
              </span>
            );
          }}
        />
        <FunctionField
          label="Рефанды"
          render={(record: CumulativeReport) => {
            if (Number(record.refundAmount) === 0) {
              return EMPTY_FIELD;
            }
            return `-${record.refundAmount} ${record.currencyCode}`;
          }}
        />
        <FunctionField
          label="Чарджбеки"
          render={(record: CumulativeReport) => {
            if (Number(record.chargeback) === 0) {
              return EMPTY_FIELD;
            }
            return `-${record.chargeback} ${record.currencyCode}`;
          }}
        />
        <FunctionField
          label="Комиссия"
          render={(record: CumulativeReport) => {
            if (Number(record.commissionValue) === 0) {
              return EMPTY_FIELD;
            }
            return `-${record.commissionValue} ${record.currencyCode}`;
          }}
        />
        <FunctionField
          label="Итого за период"
          render={(record: CumulativeReport) => {
            if (Number(record.totalAmount) === 0) {
              return EMPTY_FIELD;
            }
            const isNegative = record?.totalAmount[0] === '-';
            return (
              <Stack alignItems="center" direction="row" flexWrap="nowrap" gap={0.5}>
                {isNegative ? <ArrowDown /> : <ArrowUp />}
                <span>{`${isNegative ? record.totalAmount.substring(1) : record.totalAmount} ${
                  record.currencyCode
                }`}</span>
              </Stack>
            );
          }}
        />
        <FunctionField
          label="Нарастающий итог"
          render={(record: CumulativeReport) => {
            if (Number(record.cumulativeTotalAmount) === 0) {
              return EMPTY_FIELD;
            }
            return `${record.cumulativeTotalAmount} ${record.currencyCode}`;
          }}
        />
        <ActionsSlots
          render={(record: RaRecord) => (
            <IconButton
              component={Link}
              target="_blank"
              to={{
                pathname: '/orders_report',
                search: createFilterQueryString({
                  merchantAccount: filters.merchantAccounts,
                  merchant: filters.merchant,
                  provider: filters.provider,
                  company: filters.company,
                  transactions: {
                    createdAt: {
                      gte: record.periodFrom,
                      lte: record.periodTo,
                    },
                  },
                }),
              }}
            >
              <CompletedReport />
            </IconButton>
          )}
        />
      </StyledCumulativeReportDatagrid>
    </>
  );
};
