import { fromUnixTime, getUnixTime, parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { DEFAULT_TIMEZONE } from 'shared/mui/DatePicker/config/defaultValues';
import { TIMEZONES } from 'shared/mui/DatePicker/constants/timezones';
import { Order } from 'types';

const DATE_FORMAT = 'dd/MM/yyyy HH:mm';

export const getOrdersReportListAlertTitle = ({
  dateGte,
  dateLte,
  orders,
  timezone,
}: {
  dateGte?: number;
  dateLte?: number;
  orders: Order[];
  timezone?: string;
}) => {
  let startDate: string | number;
  if (dateGte) {
    startDate = formatInTimeZone(fromUnixTime(dateGte), timezone || DEFAULT_TIMEZONE, DATE_FORMAT);
  } else {
    const firstOrderTime = orders[0].createdAt;
    const lastOrderTime = orders[orders.length - 1].createdAt;
    const firstOrderUnixTime = getUnixTime(parseISO(firstOrderTime));
    const lastOrderUnixTime = getUnixTime(parseISO(lastOrderTime));

    startDate = formatInTimeZone(
      fromUnixTime(Math.min(firstOrderUnixTime, lastOrderUnixTime)),
      timezone || DEFAULT_TIMEZONE,
      DATE_FORMAT,
    );
  }

  const endDate = dateLte
    ? formatInTimeZone(fromUnixTime(dateLte), timezone || DEFAULT_TIMEZONE, DATE_FORMAT)
    : formatInTimeZone(new Date(), timezone || DEFAULT_TIMEZONE, DATE_FORMAT);

  const currentTimezone = `${TIMEZONES.find((tz) => tz?.name === timezone)?.offset || '+3 UTC'}`;

  return `За период отчета ${startDate} - ${endDate} (${currentTimezone}) есть неисполненные транзакции в следующих статусах:`;
};
