import { EMPTY_FIELD } from 'constants/emptyField';

import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { useRecordContext } from 'react-admin';
import { Commission } from 'types';

import { ContentStyled, RowStyled, WrapperStyled } from './FileField.styled';
import { Box } from '../../../mui/Box';
import { Typography } from '../../../mui/Typography';

export const FileField: React.FC = () => {
  const record = useRecordContext<Commission>();

  if (!record || !record.filePath) {
    return <>{EMPTY_FIELD}</>;
  }

  return (
    <WrapperStyled>
      <Box>
        <InsertDriveFileOutlinedIcon sx={{ color: 'grey' }} />
      </Box>
      <ContentStyled>
        <RowStyled>
          <a href={record.filePath} rel="noreferrer" target="_blank">
            {record.fileName}
          </a>
        </RowStyled>
        <RowStyled>
          <Typography color="secondary" variant="body2">
            Размер файла: {record?.fileSize}
          </Typography>
        </RowStyled>
      </ContentStyled>
    </WrapperStyled>
  );
};
