import { useCallback, useState } from 'react';

import InsertLinkIcon from '@mui/icons-material/InsertLink';
import MessageIcon from '@mui/icons-material/Message';
import { green, red } from '@mui/material/colors';
import { getUnixTime } from 'date-fns';
import { RecordContextProvider, useListContext } from 'react-admin';
import { DefaultValues, FieldValues, FormProvider } from 'react-hook-form';
import { Alert } from 'shared/mui/Alert/Alert';
import { AlertTitle } from 'shared/mui/Alert/AlertTitle/AlertTitle';
import { IconButton } from 'shared/mui/IconButtons';
import { FilterContextProvider } from 'shared/mui/NewFilter/context/filterContext';
import {
  FilterApplyButton,
  FilterButton,
  FilterClearButton,
  FilterIdButton,
  FilterResetButton,
} from 'shared/mui/NewFilter/FilterButtons';
import { FilterIdChip } from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChips';
import { FilterChipsToolbar } from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChipsToolbar';
import {
  FilterDrawer,
  FilterDrawerActionsButtons,
  FilterDrawerContent,
} from 'shared/mui/NewFilter/FilterDrawer';
import { FilterIdDialog } from 'shared/mui/NewFilter/FilterId/FilterIdDialog/FilterIdDialog';
import { FilterIdDialogNotFoundIdAlert } from 'shared/mui/NewFilter/FilterId/FilterIdDialog/FilterIdDialogNotFoundIdAlert/FilterIdDialogNotFoundIdAlert';
import { FilterToolbar } from 'shared/mui/NewFilter/FilterToolbar/FilterToolbar';
import { useFilters } from 'shared/mui/NewFilter/hooks/useFilters';
import {
  FilterIdsValues,
  FilterIdTypes,
  useIdFilters,
} from 'shared/mui/NewFilter/hooks/useIdFilters';
import { useSyncFilter } from 'shared/mui/NewFilter/hooks/useSyncFilter';
import { SelectColumnsButton } from 'shared/react-admin/Buttons';
import {
  DateTimeField,
  FunctionField,
  TextField,
  UserProfileField,
  WrapperField,
} from 'shared/react-admin/Fields';
import { CopyToClipboardField } from 'shared/react-admin/Fields/CopyToClipboardField/CopyToClipboardField';
import { Pagination } from 'shared/react-admin/Pagination/Pagination';
import { ReferenceField, ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';

import { OrderAdjustmentsReportAmountField } from './OrderAdjustmentsReportAmountField/OrderAdjustmentsReportAmountField';
import { OrderAdjustmentsReportChangeStatusField } from './OrderAdjustmentsReportChangeStatusField/OrderAdjustmentsReportChangeStatusField';
import { OrderAdjustmentsReportCommentDialog } from './OrderAdjustmentsReportCommentDialog/OrderAdjustmentsReportCommentDialog';
import { OrderAdjustmentsReportConfirmSearchDialog } from './OrderAdjustmentsReportConfirmSearchDialog/OrderAdjustmentsReportConfirmSearchDialog';
import { OrderAdjustmentsReportGatewayField } from './OrderAdjustmentsReportGatewayField/OrderAdjustmentsReportGatewayField';
import { DatagridStyled } from './OrderAdjustmentsReportList.styled';
import {
  OrderAdjustmentsReportListFilter,
  OrderAdjustmentsReportListFilterFormValues,
} from './OrderAdjustmentsReportListFilter/OrderAdjustmentsReportListFilter';
import { OrderAdjustmentsReportListFilterChips } from './OrderAdjustmentsReportListFilterChips/OrderAdjustmentsReportListFilterChips';
import { OrderAdjustmentsReportMerchantField } from './OrderAdjustmentsReportMerchantField/OrderAdjustmentsReportMerchantField';
import { OrderAdjustmentsReportMethodField } from './OrderAdjustmentsReportMethodField/OrderAdjustmentsReportMethodField';
import { OrderAdjustmentsReportNotifyDialog } from './OrderAdjustmentsReportNotifyDialog/OrderAdjustmentsReportNotifyDialog';
import { OrderAdjustmentsReportProviderField } from './OrderAdjustmentsReportProviderField/OrderAdjustmentsReportProviderField';
import { OrderAdjustmentsReportStatusField } from './OrderAdjustmentsReportStatusField/OrderAdjustmentsReportStatusField';
import { getDefaultFormValues } from './utils/getDefaultFormValues';
import { AdjustmentStatuses } from '../../constants/adjustmentStatuses';
import { useRecordModal } from '../../hooks/useRecordModal';
import { ExportFormat, Order } from '../../types';
import { OrderAdjustments } from '../../types/orderAdjustments';
import { getId } from '../../utils';
import { cleanEmpty } from '../../utils/cleanEmpty';
import { DirectionField } from '../Finance/OrdersReport/components/DirectionField';

const INITIAL_FORM_VALUES: OrderAdjustmentsReportListFilterFormValues = {
  appliedDate: [null, null],
  adjustmentType: [],
  adjustmentStatus: [],
  impactToBalance: null,
  requestId: null,
  user: [],
};

const INITIAL_FORM_ID_VALUES: FilterIdsValues = {
  merchantReference: [],
  id: [],
  providerReferenceId: [],
};

type Props = {
  isOpenNotifyDialog: boolean;
  exportFormat: ExportFormat;
  onCloseNotifyDialog: VoidFunction;
};

export const OrderAdjustmentsReportListContent: React.FC<Props> = ({
  exportFormat,
  isOpenNotifyDialog,
  onCloseNotifyDialog,
}) => {
  const { filterValues, data, isFetching } = useListContext();

  const [currentIdFilter, setCurrentIdFilter] = useState<FilterIdTypes>(FilterIdTypes.Id);
  const [isOpenIdDialog, setIsOpenIdDialog] = useState<boolean>(false);

  const { form, appliedFilters, setAppliedFilter, onSubmit, onReset, openDrawer, toggleDrawer } =
    useFilters<OrderAdjustmentsReportListFilterFormValues>({
      mode: 'all',
      defaultValues: getDefaultFormValues(filterValues),
      resetValues: INITIAL_FORM_VALUES,
    });

  const {
    setAppliedFilter: setAppliedIdFilter,
    onSubmit: onSubmitIds,
    onReset: onResetIds,
    appliedIdFilters,
    form: formId,
  } = useIdFilters({
    defaultValues: {
      merchantReference: filterValues?.merchantReference || [],
      id: filterValues?.order || [],
      providerReferenceId: filterValues?.providerReferenceId || [],
    },
    resetValues: INITIAL_FORM_ID_VALUES,
  });

  const getListFilters = useCallback(
    (appliedFilters: FieldValues | DefaultValues<FieldValues>) => {
      const {
        appliedDate = [null, null],
        adjustmentType,
        adjustmentStatus,
        user,
        ...restFilters
      } = appliedFilters;
      return cleanEmpty({
        ...restFilters,
        adjustmentType: adjustmentType?.map((type: string) => getId(type)),
        user,
        adjustmentStatus: adjustmentStatus?.map((status: string) => getId(status)),
        'createdAt[gte]': appliedDate?.[0] ? getUnixTime(appliedDate?.[0]) : undefined,
        'createdAt[lte]': appliedDate?.[1] ? getUnixTime(appliedDate?.[1]) : undefined,
      });
    },
    [appliedFilters, appliedIdFilters],
  );

  useSyncFilter({
    appliedFilters,
    appliedIdFilters,
    transform: getListFilters,
    transformId: (appliedIdFilters) => {
      return cleanEmpty({
        order: appliedIdFilters?.id,
      });
    },
  });

  const isListFilterExist = Object.keys(filterValues).length > 0;

  const {
    record: recordCommentDialog,
    handleOpenRecordModal: handleOpenCommentDialog,
    openRecordModal: openCommentDialog,
    handleCloseRecordModal: handleCloseCommentDialog,
  } = useRecordModal<OrderAdjustments>();

  const {
    record: recordConfirmSearchDialog,
    handleOpenRecordModal: handleOpenConfirmSearchDialog,
    openRecordModal: openConfirmSearchDialog,
    handleCloseRecordModal: handleCloseConfirmSearchDialog,
  } = useRecordModal<OrderAdjustments>();

  const onLinkClick = (record: OrderAdjustments) => {
    onReset();
    onResetIds(currentIdFilter);
    formId.setValue('id', [getId(record.order)]);
    setAppliedIdFilter('id', [getId(record.order)]);
  };

  const notFoundIds = appliedIdFilters.id?.filter(
    (id) => id && !data?.some((orderAdjustment) => getId(orderAdjustment.order) === id),
  );

  return (
    <>
      <FilterToolbar
        leftActionsSlot={[
          <FilterIdButton
            key="filter-by-id"
            onClick={() => {
              setIsOpenIdDialog(true);
            }}
          />,
          <FilterButton key="filter" onClick={toggleDrawer(true)} />,
          isListFilterExist ? <SelectColumnsButton key="settings-column" title="Колонки" /> : false,
          <FilterClearButton
            key={`clear-${currentIdFilter}`}
            onClick={() => {
              onReset();
              onResetIds(currentIdFilter);
            }}
            visible={isListFilterExist}
          />,
        ]}
      />
      <FilterDrawer onClose={toggleDrawer(false)} open={openDrawer}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FilterDrawerContent>
              <OrderAdjustmentsReportListFilter />
            </FilterDrawerContent>
            <FilterDrawerActionsButtons>
              <FilterApplyButton type="submit" />
              <FilterResetButton label="Очистить" onReset={onReset} />
            </FilterDrawerActionsButtons>
          </form>
        </FormProvider>
      </FilterDrawer>
      <FilterChipsToolbar appliedFilters={appliedFilters} appliedIdFilters={appliedIdFilters}>
        <FilterContextProvider appliedFilters={appliedFilters} setAppliedFilter={setAppliedFilter}>
          <FormProvider {...form}>
            <OrderAdjustmentsReportListFilterChips />
          </FormProvider>
        </FilterContextProvider>
        <FilterContextProvider
          appliedFilters={appliedIdFilters}
          setAppliedFilter={setAppliedIdFilter}
        >
          <FormProvider {...formId}>
            <FilterIdChip
              label="ID Kubera"
              limitTags={2}
              name="id"
              onClick={() => {
                setIsOpenIdDialog(true);
                setCurrentIdFilter(FilterIdTypes.Id);
              }}
            />
            <FilterIdChip
              label="ID Мерчантов"
              limitTags={2}
              name="merchantReference"
              onClick={() => {
                setIsOpenIdDialog(true);
                setCurrentIdFilter(FilterIdTypes.Merchant);
              }}
            />
            <FilterIdChip
              label="ID Провайдеров"
              limitTags={2}
              name="providerReferenceId"
              onClick={() => {
                setIsOpenIdDialog(true);
                setCurrentIdFilter(FilterIdTypes.Provider);
              }}
            />
          </FormProvider>
        </FilterContextProvider>
      </FilterChipsToolbar>
      <FormProvider {...formId}>
        <FilterIdDialog
          currentIdFilter={currentIdFilter}
          notFoundIds={notFoundIds}
          notFoundMerchantIds={[]}
          notFoundProviderIds={[]}
          omit={[FilterIdTypes.Provider, FilterIdTypes.Merchant]}
          onChange={(currentFilter) => setCurrentIdFilter(currentFilter)}
          onClose={() => setIsOpenIdDialog(false)}
          onReset={onResetIds}
          onSubmit={onSubmitIds}
          open={isOpenIdDialog}
        />
      </FormProvider>
      {!isListFilterExist && (
        <Alert severity="info" sx={{ margin: '15px 0 10px 0' }}>
          <AlertTitle>Отчет по корректировкам</AlertTitle>
          Для получения данных отчета по корректировкам заказов воспользуйтесь формой «
          <strong>Фильтр</strong>» или «<strong>Поиск по ID</strong>». Если требуется список
          корректировок по конкретной заявке, вы можете использовать данные из системных уведомлений
          или корпоративного мессенджера Reddy.
        </Alert>
      )}
      <FilterIdDialogNotFoundIdAlert ids={notFoundIds} isFetching={isFetching} />
      {!isFetching && isListFilterExist && (
        <>
          <DatagridStyled
            bulkActionButtons={false}
            omit={['originId', 'merchant', 'provider', 'gateway', 'method']}
            rowSx={(record: OrderAdjustments) => {
              const isRejected = record.adjustmentStatus === AdjustmentStatuses.Rejected;
              return { borderLeft: `4px solid ${isRejected ? red[700] : green[700]}` };
            }}
          >
            <TextField label="ID" sortBy="id" source="originId" />
            <ReferenceField
              label="Тип корректировки"
              link={false}
              reference="adjustment_types"
              source="adjustmentType"
            >
              <TextField source="description" />
            </ReferenceField>
            <FunctionField
              label="Движение по балансу"
              render={(record: OrderAdjustments) => `${record.impactToBalance ? 'Есть' : 'Нет'}`}
              source="impactToBalance"
            />
            <OrderAdjustmentsReportStatusField label="Статус" />
            <ReferenceOneField
              label="Заказ"
              link={false}
              reference="orders"
              sortable={false}
              source="order"
              target="id"
            >
              <CopyToClipboardField source="originId" />
            </ReferenceOneField>
            <OrderAdjustmentsReportAmountField label="Изменение суммы" source="amount" />
            <ReferenceOneField
              label="Направление"
              link={false}
              reference="orders"
              sortable={false}
              source="order"
              target="id"
            >
              <DirectionField source="direction" />
            </ReferenceOneField>
            <OrderAdjustmentsReportChangeStatusField
              label="Изменение статуса заказа"
              source="change-status"
            />
            <TextField emptyText="—" label="Причина отказа" source="rejectReason" />
            <ReferenceOneField
              label="Клиент"
              link={false}
              reference="orders"
              sortable={false}
              source="order"
              target="id"
            >
              <FunctionField
                render={(record: Order) => {
                  return (
                    <RecordContextProvider value={record}>
                      <ReferenceOneField
                        link={false}
                        reference="customers"
                        sortable={false}
                        source="customer"
                        target="id"
                      >
                        <CopyToClipboardField source="customerId" />
                      </ReferenceOneField>
                    </RecordContextProvider>
                  );
                }}
              />
            </ReferenceOneField>
            <OrderAdjustmentsReportMerchantField label="Мерчант" />
            <OrderAdjustmentsReportProviderField label="Провайдер" />
            <OrderAdjustmentsReportGatewayField label="Шлюз" />
            <OrderAdjustmentsReportMethodField label="Метод" />
            <ReferenceOneField
              label="Автор"
              link={false}
              reference="users"
              sortable={false}
              source="user"
              target="id"
            >
              <FunctionField render={() => <UserProfileField />} />
            </ReferenceOneField>
            <DateTimeField label="Дата проведения" source="createdAt" />
            <DateTimeField label="Дата обновления" source="updatedAt" />
            <WrapperField label="Действия">
              <ActionsSlots
                render={(record: OrderAdjustments) => {
                  return (
                    <>
                      <IconButton
                        disabled={!record?.comment}
                        onClick={() => handleOpenCommentDialog(record)}
                      >
                        <MessageIcon color={record?.comment ? 'primary' : 'secondary'} />
                      </IconButton>
                      <IconButton onClick={() => handleOpenConfirmSearchDialog(record)}>
                        <InsertLinkIcon />
                      </IconButton>
                    </>
                  );
                }}
              />
            </WrapperField>
          </DatagridStyled>
          <OrderAdjustmentsReportCommentDialog
            onClose={handleCloseCommentDialog}
            open={openCommentDialog}
            record={recordCommentDialog}
          />
          <OrderAdjustmentsReportConfirmSearchDialog
            onClose={handleCloseConfirmSearchDialog}
            onConfirm={() => {
              if (!recordConfirmSearchDialog) {
                return;
              }
              onLinkClick(recordConfirmSearchDialog);
              handleCloseConfirmSearchDialog();
            }}
            open={openConfirmSearchDialog}
            record={recordConfirmSearchDialog}
          />
          <Pagination />
          <OrderAdjustmentsReportNotifyDialog
            closeNotifyDialog={onCloseNotifyDialog}
            exportFormat={exportFormat}
            isLoadingData={isFetching}
            listFilters={getListFilters(appliedFilters)}
            open={isOpenNotifyDialog}
          />
        </>
      )}
    </>
  );
};
