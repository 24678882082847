import { useEffect } from 'react';

import { startOfDay } from 'date-fns';
import { useFirstRender } from 'hooks/useFirstRender';
import { Controller, useFormContext } from 'react-hook-form';
import { DEFAULT_TIMEZONE } from 'shared/mui/DatePicker/config/defaultValues';
import { DateTimeRangeTimezonePicker } from 'shared/mui/DatePicker/DateTimeRangeTimezonePicker/DateTimeRangeTimezonePicker';
import {
  AutocompleteInputShrink,
  AutocompleteMultiselectInput,
  EnumAutocompleteInputShrink,
} from 'shared/react-admin/Inputs';
import { AutocompleteMultiselectInputShrink } from 'shared/react-admin/Inputs/AutocompleteMultiselectInputShrink/AutocompleteMultiselectInputShrink';
import { ReferenceArrayInput, ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import { Currency } from 'types';

export const OrderReportNewFilter: React.FC = () => {
  const { control, watch, setValue } = useFormContext();
  const [company, merchant, provider, gateway] = watch([
    'company',
    'merchant',
    'provider',
    'gateway',
  ]);
  const [appliedDate, transactionsAppliedDate] = watch(['appliedDate', 'transactionsAppliedDate']);

  const { isFirstRender } = useFirstRender();

  useEffect(() => {
    if (!isFirstRender) {
      setValue('merchant', null);
      setValue('provider', null);
      setValue('merchantAccount', []);
      setValue('method', []);
    }
  }, [company]);

  useEffect(() => {
    if (!isFirstRender) {
      setValue('provider', []);
      setValue('merchantAccount', []);
      setValue('method', []);
    }
  }, [merchant]);

  useEffect(() => {
    if (!isFirstRender) {
      setValue('merchantAccount', []);
      setValue('gateway', []);
      setValue('method', []);
    }
  }, [provider]);

  useEffect(() => {
    if (isFirstRender) {
      setValue('method', []);
    }
  }, [gateway]);

  return (
    <>
      <Controller
        control={control}
        name="appliedDate"
        render={({ field: { onChange, onBlur, value } }) => (
          <DateTimeRangeTimezonePicker
            defaultValue={[Number(startOfDay(new Date())), Number(new Date()), DEFAULT_TIMEZONE]}
            label="Дата создания от-до"
            maxDate={new Date()}
            onBlur={onBlur}
            onChange={(v) => {
              onChange(v);
              setValue('transactionsAppliedDate', [
                transactionsAppliedDate[0],
                transactionsAppliedDate[1],
                v[2],
              ]);
            }}
            value={value}
          />
        )}
      />
      <Controller
        control={control}
        name="transactionsAppliedDate"
        render={({ field: { onChange, onBlur, value } }) => (
          <DateTimeRangeTimezonePicker
            defaultValue={[Number(startOfDay(new Date())), Number(new Date()), DEFAULT_TIMEZONE]}
            label="Дата исполнения от-до"
            maxDate={new Date()}
            onBlur={onBlur}
            onChange={(v) => {
              onChange(v);
              setValue('appliedDate', [appliedDate[0], appliedDate[1], v[2]]);
            }}
            value={value}
          />
        )}
      />
      <ReferenceInput
        filter={{ exists: { merchants: true }, archive: false }}
        perPage={100}
        reference="companies"
        source="company"
      >
        <AutocompleteInputShrink
          filterToQuery={(searchText: string) => ({ name: searchText })}
          fullWidth
          helperText={false}
          label="Компания"
          optionText="name"
          variant="outlined"
        />
      </ReferenceInput>
      <ReferenceInput
        enableGetChoices={() => !!company}
        filter={{
          companies: company,
          exists: { merchantAccounts: true },
          archive: false,
        }}
        perPage={100}
        reference="merchants"
        source="merchant"
      >
        <AutocompleteInputShrink
          disabled={!company}
          filterToQuery={(searchText: string) => ({ name: searchText })}
          fullWidth
          helperText={false}
          label="Мерчант"
          optionText="name"
          variant="outlined"
        />
      </ReferenceInput>
      <ReferenceInput
        enableGetChoices={() => !!merchant}
        filter={{ merchantAccounts: { merchants: merchant } }}
        perPage={100}
        reference="providers"
        source="provider"
      >
        <AutocompleteInputShrink
          clearOnBlur={false}
          disabled={!merchant}
          filterToQuery={(searchText: string) => ({ name: searchText })}
          helperText={false}
          label="Провайдер"
          name="provider"
          optionText="name"
          variant="outlined"
        />
      </ReferenceInput>
      <ReferenceArrayInput
        enableGetChoices={() => !!merchant?.length && !!provider?.length}
        filter={{
          merchants: merchant,
          provider: provider,
        }}
        isFilter={true}
        name="merchantAccount"
        perPage={1000}
        reference="merchant_accounts"
        resource="orders"
        source="merchantAccount"
      >
        <AutocompleteMultiselectInputShrink
          disabled={!provider?.length}
          helperText={false}
          label="Счет мерчанта"
          name="merchantAccount"
          optionText="name"
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        enableGetChoices={() => !!merchant && !!provider}
        filter={{
          merchantAccounts: { merchants: merchant },
          provider,
        }}
        name="gateway"
        perPage={100}
        reference="gateways"
        source="gateway"
      >
        <AutocompleteMultiselectInput
          InputLabelProps={{ shrink: true }}
          disabled={!provider}
          filterToQuery={(searchText: string) => ({ name: searchText })}
          helperText={false}
          label="Шлюзы"
          limitTags={1}
          name="gateway"
          optionText="name"
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        filter={{
          gateway,
        }}
        name="method"
        perPage={100}
        reference="methods"
        source="method"
      >
        <AutocompleteMultiselectInputShrink
          disabled={!gateway?.length}
          helperText={false}
          label="Метод"
          name="method"
          optionText="name"
        />
      </ReferenceArrayInput>
      <EnumAutocompleteInputShrink
        filterToQuery={(searchText: string) => ({ description: searchText })}
        fullWidth
        helperText={false}
        label="Направление"
        name="direction"
        optionText="description"
        optionValue="value"
        resource="directions"
        source="direction"
        variant="outlined"
      />
      <ReferenceArrayInput name="currency" perPage={50} reference="currencies" source="currency">
        <AutocompleteMultiselectInput
          InputLabelProps={{ shrink: true }}
          filterToQuery={(searchText: string) => ({ currency: searchText })}
          helperText={false}
          label="Валюта"
          limitTags={1}
          name="currency"
          optionText="name"
          renderChipLabel={(record: Currency) => `${record?.alphaCode}, ${record?.name}`}
        />
      </ReferenceArrayInput>
    </>
  );
};
