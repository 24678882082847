import { useState } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton } from '@mui/material';
import { useRecordModal } from 'hooks/useRecordModal';
import { useListContext } from 'react-admin';
import { FormProvider } from 'react-hook-form';
import { Alert } from 'shared/mui/Alert/Alert';
import { Button } from 'shared/mui/Buttons';
import { DatagridFieldLabel } from 'shared/mui/Labels/DatagridFieldLabel/DatagridFieldLabel';
import { FilterContextProvider } from 'shared/mui/NewFilter/context/filterContext';
import { FilterClearButton, FilterIdButton } from 'shared/mui/NewFilter/FilterButtons';
import { FilterIdChip } from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChips';
import { FilterChipsToolbar } from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChipsToolbar';
import { FilterIdDialog } from 'shared/mui/NewFilter/FilterId/FilterIdDialog/FilterIdDialog';
import { FilterIdDialogNotFoundIdAlert } from 'shared/mui/NewFilter/FilterId/FilterIdDialog/FilterIdDialogNotFoundIdAlert/FilterIdDialogNotFoundIdAlert';
import { FilterToolbar } from 'shared/mui/NewFilter/FilterToolbar/FilterToolbar';
import { FilterIdTypes, useIdFilters } from 'shared/mui/NewFilter/hooks/useIdFilters';
import { useSyncFilter } from 'shared/mui/NewFilter/hooks/useSyncFilter';
import { Stack } from 'shared/mui/Stack/Stack';
import { Typography } from 'shared/mui/Typography';
import {
  ChipField,
  DateTimeField,
  OrderStatusChipField,
  TextField,
} from 'shared/react-admin/Fields';
import { CopyToClipboardField } from 'shared/react-admin/Fields/CopyToClipboardField/CopyToClipboardField';
import {
  ReferenceField,
  ReferenceLink,
  ReferenceOneField,
} from 'shared/react-admin/ReferenceFields';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';
import { Order } from 'types';
import { getId } from 'utils';

import { StyledDatagrid } from './OrderAdjustmentsForm.styled';
import { OrderAdjustmentsFormAlert } from './OrderAdjustmentsFormAlert/OrderAdjustmentsFormAlert';
import { useOrderAdjustmentsForm } from '../hooks/useOrderAdjustmentsForm';
import { OrderAdjustmentsAmountDialog } from '../OrderAdjustmentsAmountDialog/OrderAdjustmentsAmountDialog';
import { OrderAdjustmentsAmountField } from '../OrderAdjustmentsAmountField/OrderAdjustmentsAmountField';
import { OrderAdjustmentsCommentButton } from '../OrderAdjustmentsCommentButton/OrderAdjustmentsCommentButton';
import { OrderAdjustmentsCommentDialog } from '../OrderAdjustmentsCommentDialog/OrderAdjustmentsCommentDialog';
import { OrderAdjustmentsDatagridBulkActionsToolbar } from '../OrderAdjustmentsDatagridBulkActionsToolbar';
import { OrderAdjustmentsDialog } from '../OrderAdjustmentsDialog/OrderAdjustmentsDialog';
import { OrderAdjustmentsGroupCommentDialog } from '../OrderAdjustmentsGroupCommentDialog/OrderAdjustmentsGroupCommentDialog';
import { OrderAdjustmentsProviderField } from '../OrderAdjustmentsProviderField/OrderAdjustmentsProviderField';
import { OrderAdjustmentsSubmitDialog } from '../OrderAdjustmentsSubmitDialog/OrderAdjustmentsSubmitDialog';

export const OrderAdjustmentsForm = (): JSX.Element => {
  const [currentIdFilter, setCurrentIdFilter] = useState<FilterIdTypes>(FilterIdTypes.Id);
  const [isOpenIdDialog, setIsOpenIdDialog] = useState<boolean>(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);
  const { filterValues, data: orders = [], isFetching } = useListContext<Order>();

  const {
    record: recordCommentDialog,
    handleCloseRecordModal: handleCloseCommentDialog,
    openRecordModal: openCommentDialog,
    handleOpenRecordModal: handleOpenCommentDialog,
  } = useRecordModal<Order>();
  const {
    handleCloseRecordModal: handleCloseGroupCommentDialog,
    openRecordModal: openGroupCommentDialog,
    handleOpenRecordModal: handleOpenGroupCommentDialog,
  } = useRecordModal<Order>();
  const {
    handleCloseRecordModal: handleCloseAmountDialog,
    openRecordModal: openAmountDialog,
    handleOpenRecordModal: handleOpenAmountDialog,
  } = useRecordModal();
  const {
    handleCloseRecordModal: handleCloseAdjustmentsDialog,
    openRecordModal: openAdjustmentsDialog,
    handleOpenRecordModal: handleOpenAdjustmentsDialog,
  } = useRecordModal();
  const {
    handleCloseRecordModal: handleCloseAdjustmentsSubmitDialog,
    openRecordModal: openAdjustmentsSubmitDialog,
    handleOpenRecordModal: handleOpenAdjustmentsSubmitDialog,
  } = useRecordModal();

  const {
    setAppliedFilter: setAppliedIdFilter,
    onSubmit: onSubmitIds,
    onReset: onResetIds,
    appliedIdFilters,
    form: formId,
  } = useIdFilters({
    defaultValues: {
      merchantReference: filterValues?.merchantReference || [],
      id: filterValues?.id || [],
      providerReferenceId: filterValues?.providerReferenceId || [],
    },
    resetValues: {
      merchantReference: [],
      id: [],
      providerReferenceId: [],
    },
  });

  useSyncFilter({
    appliedIdFilters,
  });

  const { orderAdjustmentsForm, resetOrderAdjustmentsForm } = useOrderAdjustmentsForm();

  const handleOrdersExclude = (records: Order[]) => {
    const updatedIdFilters = appliedIdFilters[currentIdFilter].filter(
      (id: string) => !records.some((record) => id === getId(record[currentIdFilter])),
    );
    if (!updatedIdFilters.length) {
      onResetIds(currentIdFilter);
      resetOrderAdjustmentsForm();
      return;
    }
    formId.setValue(currentIdFilter, updatedIdFilters);
    onSubmitIds({ ...appliedIdFilters, [currentIdFilter]: updatedIdFilters });
  };

  const isListFilterExist = Object.keys(filterValues).length > 0;
  const notFoundIds = appliedIdFilters.id?.filter(
    (id) => id && !orders?.some((order) => getId(order.id) === id),
  );
  const foundIds = appliedIdFilters.id?.filter(
    (id) => id && orders?.some((order) => getId(order.id) === id),
  );

  return (
    <>
      <FilterToolbar
        leftActionsSlot={[
          <FilterIdButton
            key="filter-by-id"
            onClick={() => {
              setIsOpenIdDialog(true);
            }}
          />,
          <Button
            color="secondary"
            disabled={!isListFilterExist || (notFoundIds?.length > 0 && foundIds?.length <= 0)}
            key="adjustments-dialog"
            label="Настройки корректировки"
            onClick={handleOpenAdjustmentsDialog}
          />,
          <FilterClearButton
            key={`clear-${currentIdFilter}`}
            label="Сбросить"
            onClick={() => {
              onResetIds(currentIdFilter);
              resetOrderAdjustmentsForm();
            }}
            visible={isListFilterExist}
          />,
        ]}
        sx={{ padding: 0, paddingTop: '4px' }}
      />
      <FilterChipsToolbar appliedIdFilters={appliedIdFilters} sx={{ padding: '16px 0' }}>
        <FilterContextProvider
          appliedFilters={appliedIdFilters}
          setAppliedFilter={setAppliedIdFilter}
        >
          <FormProvider {...formId}>
            <FilterIdChip
              label="ID Kubera"
              limitTags={2}
              name="id"
              onClick={() => {
                setIsOpenIdDialog(true);
                setCurrentIdFilter(FilterIdTypes.Id);
              }}
            />
            <FilterIdChip
              label="ID Мерчантов"
              limitTags={2}
              name="merchantReference"
              onClick={() => {
                setIsOpenIdDialog(true);
                setCurrentIdFilter(FilterIdTypes.Merchant);
              }}
            />
            <FilterIdChip
              label="ID Провайдеров"
              limitTags={2}
              name="providerReferenceId"
              onClick={() => {
                setIsOpenIdDialog(true);
                setCurrentIdFilter(FilterIdTypes.Provider);
              }}
            />
          </FormProvider>
        </FilterContextProvider>
      </FilterChipsToolbar>
      <FormProvider {...formId}>
        <FilterIdDialog
          currentIdFilter={currentIdFilter}
          notFoundIds={notFoundIds}
          onChange={(currentFilter) => setCurrentIdFilter(currentFilter)}
          onClose={() => setIsOpenIdDialog(false)}
          onReset={onResetIds}
          onSubmit={onSubmitIds}
          open={isOpenIdDialog}
        />
      </FormProvider>
      <FormProvider {...orderAdjustmentsForm}>
        <Box
          sx={{
            minHeight: '100%',
            display: 'initial',
          }}
        >
          {isFormSubmitted && (
            <Alert sx={{ mt: '24px', '& .MuiAlert-message': { flex: 1 } }}>
              <Stack alignItems="center" direction="row" justifyContent="space-between">
                <Typography fontWeight={500} sx={{ mb: '4px' }}>
                  Данные по корректировке успешно отправлены
                </Typography>
                <IconButton onClick={() => setIsFormSubmitted(false)} size="small">
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              </Stack>
              <Typography variant="body2">
                По завершению процесса корректировки заказов, вам будет отправлены уведомления в
                панели администрирования и Reddy с результатами обработки данных.
              </Typography>
            </Alert>
          )}
          {notFoundIds?.length <= 0 && !isFetching && !isListFilterExist && (
            <OrderAdjustmentsFormAlert
              onClick={() => {
                setIsOpenIdDialog(true);
              }}
            />
          )}
          <FilterIdDialogNotFoundIdAlert ids={notFoundIds} isFetching={isFetching} />
          {!isFetching && isListFilterExist && (
            <StyledDatagrid
              bulkActionButtons={
                <OrderAdjustmentsDatagridBulkActionsToolbar
                  handleOpenAmountDialog={handleOpenAmountDialog}
                  handleOpenGroupCommentDialog={handleOpenGroupCommentDialog}
                  handleOrdersExclude={handleOrdersExclude}
                />
              }
            >
              <CopyToClipboardField label="Мерчант ID" source="merchantReference" underline />
              <DateTimeField label="Создан" source="createdAt" />
              <OrderAdjustmentsAmountField
                label={
                  <DatagridFieldLabel primaryText="Сумма" secondaryText="заказа / транзакции" />
                }
                source="amount"
              />
              <ReferenceField label="Направление" reference="directions" source="direction">
                <ChipField source="description" />
              </ReferenceField>
              <ReferenceField
                label="Статус"
                reference="new/order_statuses"
                sortable={false}
                source="status"
              >
                <OrderStatusChipField source="description" />
              </ReferenceField>
              <ReferenceOneField
                label="Клиент"
                link={false}
                reference="customers"
                sortable={false}
                source="customer"
                target="id"
              >
                <TextField source="customerId" />
              </ReferenceOneField>
              <ReferenceOneField
                label="Мерчант"
                link={false}
                reference="merchants"
                sortable={false}
                source="merchant"
                target="id"
              >
                <ChipField source="name" />
              </ReferenceOneField>
              <OrderAdjustmentsProviderField
                label={
                  <DatagridFieldLabel primaryText="Провайдер" secondaryText="платежная система" />
                }
                source="provider"
              />
              <ReferenceOneField
                label="Шлюз"
                link={false}
                reference="gateways"
                sortable={false}
                source="gateway"
                target="id"
              >
                <TextField source="name" />
              </ReferenceOneField>
              <ReferenceOneField
                label="Метод"
                link={false}
                reference="methods"
                source="method"
                target="id"
              >
                <ReferenceLink type="show">
                  <TextField source="name" />
                </ReferenceLink>
              </ReferenceOneField>
              <ActionsSlots
                render={(record: Order) => (
                  <>
                    <OrderAdjustmentsCommentButton
                      onClick={() => handleOpenCommentDialog(record)}
                      orderId={record.id}
                    />
                    <IconButton onClick={() => handleOrdersExclude([record])} size="small">
                      <CancelIcon fontSize="inherit" />
                    </IconButton>
                  </>
                )}
              />
            </StyledDatagrid>
          )}
        </Box>
        {recordCommentDialog && (
          <OrderAdjustmentsCommentDialog
            onClose={handleCloseCommentDialog}
            open={openCommentDialog}
            record={recordCommentDialog}
          />
        )}
        <OrderAdjustmentsGroupCommentDialog
          onClose={handleCloseGroupCommentDialog}
          open={openGroupCommentDialog}
        />
        <OrderAdjustmentsAmountDialog onClose={handleCloseAmountDialog} open={openAmountDialog} />
        <OrderAdjustmentsDialog
          onClose={handleCloseAdjustmentsDialog}
          onSubmit={handleOpenAdjustmentsSubmitDialog}
          open={openAdjustmentsDialog}
        />
        <OrderAdjustmentsSubmitDialog
          onClose={handleCloseAdjustmentsSubmitDialog}
          onSubmit={() => {
            setIsFormSubmitted(true);
            onResetIds(currentIdFilter);
            resetOrderAdjustmentsForm();
          }}
          open={openAdjustmentsSubmitDialog}
        />
      </FormProvider>
    </>
  );
};
