import React from 'react';

import { useFlag } from '@unleash/proxy-client-react';
import { BalanceReportFilterValues } from 'components/Finance/BalanceReport/BalanceReportList';
import { format, fromUnixTime } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { useGetList } from 'react-admin';
import { Alert, AlertProps } from 'shared/mui/Alert/Alert';
import { DEFAULT_TIMEZONE } from 'shared/mui/DatePicker/config/defaultValues';
import { TIMEZONES } from 'shared/mui/DatePicker/constants/timezones';
import { Typography } from 'shared/mui/Typography';
import { Currency } from 'types';

type Props = {
  onClose: () => void;
  filters: BalanceReportFilterValues;
} & AlertProps;

export const BalanceReportSuccessAlert: React.FC<Props> = ({ onClose, filters }) => {
  const { currency, periodFrom, periodTo, conversionRateType, appliedDate } = filters;
  const { data: currencyData } = useGetList<Currency>('currencies', {
    filter: { alphaCode: currency },
    sort: { field: 'id', order: 'ASC' },
  });
  const currentFilterCurrency = currencyData?.[0]
    ? `${currencyData?.[0].alphaCode}, ${currencyData?.[0].name}`
    : '';

  const isNewBalanceFiltersEnabled = useFlag('wppa-6931-new-balance-report-filter');

  const alertText = isNewBalanceFiltersEnabled
    ? `Отчет сформирован за период ${appliedDate?.[0] ? formatInTimeZone(appliedDate?.[0], appliedDate?.[2] || DEFAULT_TIMEZONE, 'dd.MM.yyyy') : ''} -
          ${appliedDate?.[1] ? formatInTimeZone(appliedDate?.[1], appliedDate?.[2] || DEFAULT_TIMEZONE, 'dd.MM.yyyy') : ''} (${TIMEZONES.find((tz) => tz?.name === appliedDate?.[2])?.offset || '+3 UTC'}) и сконвертирован в валюте
          "${currentFilterCurrency}" на ${conversionRateType === 'actual' ? 'текущую' : 'конечную'} дату запроса.`
    : `Отчет сформирован за период ${periodFrom ? format(fromUnixTime(periodFrom), 'dd.MM.yyyy HH:mm') : ''} -
          ${periodTo ? format(fromUnixTime(periodTo), 'dd.MM.yyyy HH:mm') : ''} и сконвертирован в валюте
          "${currentFilterCurrency}" на ${conversionRateType === 'actual' ? 'текущую' : 'конечную'} дату запроса.`;

  return (
    <Alert
      onClose={onClose}
      severity="success"
      sx={isNewBalanceFiltersEnabled ? { marginY: '16px' } : { marginTop: '16px' }}
    >
      <Typography fontWeight={500}>
        {conversionRateType === 'actual' ? 'Актуальный курс' : 'Исторический курс'}
      </Typography>
      <Typography>{alertText}</Typography>
    </Alert>
  );
};
