import { useState } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ListItemIcon, ListItemText } from 'shared/mui/List';
import { MenuItem } from 'shared/mui/MenuItem/MenuItem';
import { Tooltip } from 'shared/mui/Tooltip';
import { useDatagridActionsContext } from 'shared/react-admin/Datagrids/DatagridActions/context/datagridActionsContext';

import { useGetOrderCopyData } from './hooks/useGetOrderCopyData';

type Props = {
  timezone: string;
};

export const OrdersListLocalDatagridCopyButton: React.FC<Props> = ({ timezone }) => {
  const { copyData } = useGetOrderCopyData(timezone);
  const { onCloseMenu } = useDatagridActionsContext();

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipText, setTooltipText] = useState('');

  const handleCopy = async () => {
    const openTooltip = () => {
      setTooltipOpen(true);

      setTimeout(() => {
        setTooltipOpen(false);
        onCloseMenu();
      }, 1000);
    };
    try {
      await navigator.clipboard.writeText(copyData);

      setTooltipText('Cкопировано');
      openTooltip();
    } catch (e: unknown) {
      setTooltipText('Копирование не удалось');
      openTooltip();
    }
  };

  return (
    <Tooltip disableHoverListener open={tooltipOpen} title={tooltipText}>
      <MenuItem onClick={handleCopy} sx={{ width: '200px' }}>
        <ListItemIcon>
          <ContentCopyIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Копировать" />
      </MenuItem>
    </Tooltip>
  );
};
