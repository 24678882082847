import { formatInTimeZone } from 'date-fns-tz';
import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { DEFAULT_TIMEZONE } from 'shared/mui/DatePicker/config/defaultValues';
import { DeleteIconButton, EditDialogIconButton, ShowLinkIconButton } from 'shared/mui/IconButtons';
import { UserActionsIconButton } from 'shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { Link } from 'shared/mui/Link/Link';
import {
  ChipField,
  DateTimeField,
  FunctionField,
  NumberField,
  SingleFieldList,
  TextField,
  WrapperField,
} from 'shared/react-admin/Fields';
import { CommissionStatusChipField } from 'shared/react-admin/Fields/CommissionStatusChipField/CommissionStatusChipField';
import {
  ReferenceArrayField,
  ReferenceField,
  ReferenceOneField,
} from 'shared/react-admin/ReferenceFields';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';

import { StyledDatagrid } from './CommissionsListDatagrid.styled';
import { Commission, Currency, Permission } from '../../../../types';
import { CommissionsDeleteDialog } from '../../components/CommissionsDeleteDialog';

export const CommissionsListDatagrid: React.FC = () => {
  const { hasResourceAccess, hasResourcePermissions } = usePermissions();

  const {
    record: recordDelete,
    handleOpenRecordModal: handleOpenRecordModalDelete,
    openRecordModal: openRecordModalDelete,
    handleCloseRecordModal: handleCloseRecordModalDelete,
  } = useRecordModal<Commission>();

  return (
    <>
      <StyledDatagrid bulkActionButtons={false}>
        <TextField label="ID" sortBy="id" source="originId" />
        <TextField emptyText="—" label="Версий" source="parent" />
        <FunctionField
          label="Период активности"
          render={(record: Commission) => {
            if (!record?.startDate || !record?.endDate) {
              return '—';
            }

            const start = formatInTimeZone(record?.startDate, DEFAULT_TIMEZONE, 'dd.MM.yyyy');
            const end = formatInTimeZone(record?.endDate, DEFAULT_TIMEZONE, 'dd.MM.yyyy');

            return `${start} - ${end}`;
          }}
          source="startDate"
        />
        <FunctionField
          label="Название"
          render={(record: Commission) => {
            return (
              <>
                <Link>{record.name}</Link> <ShowLinkIconButton />
              </>
            );
          }}
          source="name"
        />
        <ReferenceField
          emptyText="—"
          label="Статус"
          link={false}
          reference="commission_statuses"
          source="status"
        >
          <CommissionStatusChipField source="description" />
        </ReferenceField>
        {hasResourceAccess('providers') && (
          <ReferenceOneField
            label="Провайдер"
            reference="providers"
            sortBy="provider.name"
            source="provider"
            target="id"
          >
            <TextField source="name" />
          </ReferenceOneField>
        )}
        {hasResourceAccess('merchants') && (
          <ReferenceOneField
            emptyText="—"
            label="Мерчант"
            link="show"
            reference="merchants"
            sortBy="merchant.name"
            source="merchant"
            target="id"
          >
            <TextField source="name" />
          </ReferenceOneField>
        )}
        {hasResourceAccess('currencies') && (
          <ReferenceOneField
            label="Валюта"
            reference="currencies"
            sortBy="currency.alphaCode"
            source="currency"
            target="id"
          >
            <FunctionField
              render={(record: Currency) => `${record?.alphaCode} / ${record?.name}`}
            />
          </ReferenceOneField>
        )}
        {hasResourceAccess('transaction_types') && (
          <ReferenceArrayField
            label="Типы транзакций"
            reference="transaction_types"
            sortable={false}
            source="transactionTypes"
          >
            <SingleFieldList linkType={false}>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
        )}
        <FunctionField
          label="Процент"
          render={(record: Commission) => {
            if (!record?.percent) {
              return '—';
            }

            return `${record?.percent}%`;
          }}
          source="percent"
        />
        <NumberField emptyText="—" label="Фикс." source="fix" />
        <NumberField emptyText="—" label="Макс." source="max" />
        <NumberField emptyText="—" label="Мин." source="min" />
        <DateTimeField label="Дата создания" source="createdAt" />
        <DateTimeField label="Дата редактир." source="updatedAt" />
        <WrapperField label="Действия">
          <ActionsSlots
            render={(record: Commission) => (
              <>
                {hasResourceAccess('user_actions') && (
                  <UserActionsIconButton entityName="commission" />
                )}
                {hasResourcePermissions('commissions', [Permission.Full, Permission.Update]) && (
                  <EditDialogIconButton />
                )}
                <ShowLinkIconButton />
                {hasResourcePermissions('commissions', [Permission.Full, Permission.Delete]) && (
                  <DeleteIconButton onClick={() => handleOpenRecordModalDelete(record)} />
                )}
              </>
            )}
          />
        </WrapperField>
      </StyledDatagrid>
      {openRecordModalDelete && (
        <CommissionsDeleteDialog
          onClose={handleCloseRecordModalDelete}
          open={openRecordModalDelete}
          record={recordDelete}
        />
      )}
      {/*<CommissionsListCommentDialog onClose={() => console.log('close')} open={true} />*/}
    </>
  );
};
