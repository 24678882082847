export const EXPORT_FIELDS_ORDERS = [
  {
    index: '0',
    source: 'id',
    label: 'ID kubera',
  },
  {
    index: '1',
    source: 'createdAt',
    label: 'Время создания',
  },
  {
    index: '2',
    source: 'amount',
    label: 'Сумма',
  },
  {
    index: '3',
    source: 'currency',
    label: 'Валюта',
  },
  {
    index: '4',
    source: 'status',
    label: 'Статус',
  },
  {
    index: '5',
    source: 'type',
    label: 'Направление',
  },
  {
    index: '6',
    source: 'customer',
    label: 'Клиент',
  },
  {
    index: '7',
    source: 'customerAccount',
    label: 'Данные клиента',
  },
  {
    index: '8',
    source: 'method',
    label: 'Метод',
  },
  {
    index: '9',
    source: 'gateway',
    label: 'Шлюз',
  },
  {
    index: '10',
    source: 'merchant',
    label: 'Мерчант',
  },
  {
    index: '11',
    source: 'orderErrorReason',
    label: 'Причина отклонения',
  },
  {
    index: '12',
    source: 'merchantReference',
    label: 'Id мерчанта',
  },
  {
    index: '13',
    source: 'providerReferenceId',
    label: 'Id провайдера',
  },
  {
    index: '14',
    source: 'provider',
    label: 'Провайдер',
  },
];
