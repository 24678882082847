import { Controller, useFormContext } from 'react-hook-form';
import { DateRangePickerValue } from 'shared/mui/DatePicker/DateRangePicker/DateRangePicker';
import { DateTimeRangePicker } from 'shared/mui/DatePicker/DateTimeRangePicker/DateTimeRangePicker';
import { AutocompleteMultiselectInput } from 'shared/react-admin/Inputs';
import { SelectInputShrink } from 'shared/react-admin/Inputs/SelectInputShrink/SelectInputShrink';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';
import { ReferenceArrayInput } from 'shared/react-admin/ReferenceInputs';

import { User } from '../../../types';
import { IMPACT_TO_BALANCE_SELECT_ITEMS } from '../constants/impactToBalance';

export type OrderAdjustmentsReportListFilterFormValues = {
  appliedDate: [DateRangePickerValue, DateRangePickerValue];
  adjustmentType: string[];
  impactToBalance: '1' | '0' | null;
  requestId: string | null;
  adjustmentStatus: string[];
  user: string[];
};

export const OrderAdjustmentsReportListFilter: React.FC = () => {
  const { control } = useFormContext<OrderAdjustmentsReportListFilterFormValues>();

  return (
    <>
      <Controller
        control={control}
        name="appliedDate"
        render={({ field: { onChange, onBlur, value } }) => (
          <DateTimeRangePicker
            label="Время создания / период"
            maxDate={new Date()}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
          />
        )}
      />
      <ReferenceArrayInput
        name="adjustmentType"
        perPage={100}
        reference="adjustment_types"
        source="adjustmentType"
      >
        <AutocompleteMultiselectInput
          InputLabelProps={{ shrink: true }}
          filterToQuery={(searchText: string) => ({ name: searchText })}
          helperText={false}
          label="Тип операции"
          limitTags={1}
          name="adjustmentType"
          optionText="description"
        />
      </ReferenceArrayInput>
      <SelectInputShrink
        choices={IMPACT_TO_BALANCE_SELECT_ITEMS}
        fullWidth
        helperText={false}
        id="impactToBalance"
        label="Движение по балансу"
        name="impactToBalance"
        optionText="label"
        source="impactToBalance"
        variant="outlined"
      />
      <ReferenceArrayInput
        name="adjustmentStatus"
        perPage={100}
        reference="adjustment_statuses"
        source="adjustmentStatus"
      >
        <AutocompleteMultiselectInput
          InputLabelProps={{ shrink: true }}
          filterToQuery={(searchText: string) => ({ name: searchText })}
          helperText={false}
          label="Статус операции"
          limitTags={1}
          name="adjustmentStatus"
          optionText="description"
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput name="user" perPage={200} reference="users" source="user">
        <AutocompleteMultiselectInput
          InputLabelProps={{ shrink: true }}
          filterToQuery={(searchText: string) => ({
            fullName: searchText,
          })}
          helperText={false}
          label="Автор / Инициатор"
          name="user"
          optionText="name"
          renderChipLabel={(choice: User) => `${choice.firstName} ${choice.lastName}`}
          size="small"
        />
      </ReferenceArrayInput>
      <TextInputShrink
        fullWidth
        helperText={false}
        label="ID заявки"
        source="requestId"
        variant="outlined"
      />
    </>
  );
};
