import { EMPTY_FIELD } from 'constants/emptyField';

import { useFlag } from '@unleash/proxy-client-react';
import { DirectionField } from 'components/Finance/OrdersReport/components/DirectionField';
import { useLocalDatagrid } from 'hooks/useLocalDatagrid';
import { useLocalDatagridPagination } from 'hooks/useLocalDatagridPagination';
import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { ListToolbar, RaRecord, TopToolbar } from 'react-admin';
import { Alert } from 'shared/mui/Alert/Alert';
import { DEFAULT_TIMEZONE } from 'shared/mui/DatePicker/config/defaultValues';
import { TablePagination } from 'shared/mui/Table';
import { Typography } from 'shared/mui/Typography';
import { DatagridConfigurable } from 'shared/react-admin/Datagrids';
import {
  ChipField,
  TextField,
  FunctionField,
  NumberField,
  DateTimeField,
} from 'shared/react-admin/Fields';
import { ReferenceField, ReferenceOneField } from 'shared/react-admin/ReferenceFields';
import { ReferenceLink } from 'shared/react-admin/ReferenceFields/ReferenceLink/ReferenceLink';
import { SelectColumnButton } from 'shared/react-admin/Table/SelectColumnButton/SelectColumnButton';
import { CommissionShowDialog } from 'shared/widgets/CommissionShowDialog/CommissionShowDialog';
import { MerchantShowAccountsShowDialog } from 'shared/widgets/MerchantAccountShowDialog/MerchantAccountShowDialog';
import { appColors } from 'themes/variables';
import { Commission, MerchantAccount, Order } from 'types';
import { formatCurrency } from 'utils';

type Props = {
  orders: Order[];
  total: number;
  isLoading: boolean;
  timezone?: string;
};

export const OrdersReportLocalDatagrid: React.FC<Props> = ({
  total,
  isLoading,
  orders = [],
  timezone = DEFAULT_TIMEZONE,
}) => {
  const { hasResourceAccess } = usePermissions();
  const {
    record: merchantAccount,
    handleOpenRecordModal: handleOpenMerchantAccountModal,
    handleCloseRecordModal: handleCloseMerchantAccountModal,
    openRecordModal: openMerchantAccountModal,
  } = useRecordModal<MerchantAccount>();

  const { record, handleOpenRecordModal, handleCloseRecordModal, openRecordModal } =
    useRecordModal<Commission>();

  const { sortedData, sort, setSort } = useLocalDatagrid({
    data: orders,
    defaultSort: {
      field: 'createdAt',
      order: 'ASC',
    },
  });

  const { dataPerPage, ...paginationProps } = useLocalDatagridPagination<Order>({
    data: sortedData,
    defaultPage: 0,
    defaultRowsPerPage: 10,
  });

  function getCommissionValue(record: RaRecord, fieldName: string): string {
    if (!record?.[fieldName]) {
      return EMPTY_FIELD;
    }

    return `${formatCurrency(record?.[fieldName]?.value)} ${
      record?.[fieldName]?.currency?.alphaCode
    }`;
  }

  const isNewFilterEnabled = useFlag('wppa_6927_new_orders_report_filter');

  const PostListActions = () => (
    <TopToolbar>
      <SelectColumnButton />
    </TopToolbar>
  );

  return (
    <>
      {record && (
        <CommissionShowDialog
          commission={record}
          onClose={handleCloseRecordModal}
          open={openRecordModal}
        />
      )}
      {!isNewFilterEnabled && <ListToolbar actions={<PostListActions />} />}
      <DatagridConfigurable
        bulkActionButtons={false}
        data={dataPerPage}
        empty={
          <Alert severity="info">
            <Typography fontWeight={500} mb="5px">
              Здесь пока нет отчета по исполненным транзакциям.
            </Typography>
            <Typography fontSize={14}>
              Чтобы сформировать отчет, выберите параметры в фильтре и нажмите кнопку “Применить”.
            </Typography>
          </Alert>
        }
        isLoading={isLoading}
        setSort={setSort}
        sort={sort}
        sx={{ width: '100%' }}
      >
        <ReferenceField label="Kubera ID" link={false} reference="orders" sortBy="id" source="@id">
          <ReferenceLink type="show">
            <TextField source="originId" />
          </ReferenceLink>
        </ReferenceField>
        <DateTimeField label="Создан" resource="orders" source="createdAt" timezone={timezone} />
        <DateTimeField
          emptyText={EMPTY_FIELD}
          label="Исполнен"
          resource="orders"
          source="completedAt"
          timezone={timezone}
        />
        <NumberField
          label="Сумма заказа"
          options={{
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }}
          resource="orders"
          source="amount"
        />
        {hasResourceAccess('currencies') && (
          <ReferenceOneField
            label="Валюта заказа"
            link={false}
            reference="currencies"
            resource="currencies"
            sortable={false}
            source="currency"
            target="id"
          >
            <FunctionField
              render={(record: RaRecord | undefined) => record?.alphaCode}
              resource="orders"
            />
          </ReferenceOneField>
        )}
        {hasResourceAccess('commissions') && (
          <FunctionField
            label="Комиссионное правило"
            render={(record: RaRecord) => {
              if (!record?.commission) {
                return EMPTY_FIELD;
              }
              return (
                <ReferenceOneField
                  link={false}
                  reference="commissions"
                  resource="commissions"
                  sortable={false}
                  source="commission"
                  target="id"
                >
                  <FunctionField
                    render={(record: Commission) => (
                      <ChipField
                        color="primary"
                        onClick={() => {
                          handleOpenRecordModal(record);
                        }}
                        source="name"
                        style={{ cursor: 'pointer' }}
                      />
                    )}
                  />
                </ReferenceOneField>
              );
            }}
          />
        )}
        <FunctionField
          label="Комиссия по заказу"
          render={(record: RaRecord) =>
            getCommissionValue(record, 'commissionValueByCurrencyOrder')
          }
        />
        <FunctionField
          label="Комиссия по правилу"
          render={(record: RaRecord) =>
            getCommissionValue(record, 'commissionValueByCurrencyCommission')
          }
        />
        <FunctionField
          label="Итого по заказу"
          render={(record: RaRecord) =>
            record?.totalAmount ? (
              <>
                <Typography component="span" variant="body2">
                  {formatCurrency(record?.totalAmount) + ' '}
                </Typography>
                <ReferenceOneField
                  link={false}
                  reference="currencies"
                  resource="orders"
                  sortable={false}
                  source="currency"
                  target="id"
                >
                  <TextField source="alphaCode" />
                </ReferenceOneField>
              </>
            ) : (
              EMPTY_FIELD
            )
          }
        />
        <DirectionField label="Направление" source="direction" />
        <ReferenceOneField
          label="Мерчант"
          link={false}
          reference="merchants"
          resource="orders"
          sortable={false}
          source="merchant"
          target="id"
        >
          <ReferenceLink type="show">
            <ChipField source="name" />
          </ReferenceLink>
        </ReferenceOneField>
        <ReferenceOneField
          label="Шлюз"
          link={false}
          reference="gateways"
          resource="orders"
          sortable={false}
          source="gateway"
          target="id"
        >
          <ReferenceLink type="show">
            <TextField source="name" />
          </ReferenceLink>
        </ReferenceOneField>
        <ReferenceOneField
          emptyText={EMPTY_FIELD}
          label="Метод"
          link={false}
          reference="methods"
          resource="methods"
          sortable={false}
          source="method"
          target="id"
        >
          <ReferenceLink type="show">
            <TextField source="name" sx={{ marginBottom: '10px' }} />
          </ReferenceLink>
        </ReferenceOneField>
        <ReferenceField
          label="Счет мерчанта"
          link={false}
          reference="merchant_accounts"
          resource="merchant_accounts"
          sortable={false}
          source="merchantAccount"
        >
          <FunctionField
            render={(record: MerchantAccount) => (
              <Typography
                color="secondary"
                onClick={() => handleOpenMerchantAccountModal(record)}
                sx={{ color: appColors.primary.main, cursor: 'pointer' }}
                variant="body2"
              >
                {record.name}
              </Typography>
            )}
          />
        </ReferenceField>
        <TextField label="Мерчант ID" resource="orders" source="merchantReference" />
        <FunctionField
          label="Провайдер ID"
          render={(record: RaRecord | undefined) => {
            return record?.providerReferenceId || EMPTY_FIELD;
          }}
          resource="orders"
        />
      </DatagridConfigurable>
      {total > 0 && (
        <TablePagination
          count={total}
          sx={{ '.MuiToolbar-root': { maxHeight: 0 } }}
          {...paginationProps}
        />
      )}
      {merchantAccount && (
        <MerchantShowAccountsShowDialog
          merchantAccount={merchantAccount}
          onClose={handleCloseMerchantAccountModal}
          open={openMerchantAccountModal}
        />
      )}
    </>
  );
};
