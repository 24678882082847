import { useState } from 'react';

import SettingsIcon from '@mui/icons-material/Settings';
import { ConfigurableDatagridColumn, useStore, useResourceContext } from 'react-admin';
import { Button } from 'shared/mui/Buttons';
import { List } from 'shared/mui/List/List';
import { Popover } from 'shared/mui/Popover/Popover';
import { sortColumnsByIndex } from 'utils';

import { FieldToggle } from './FieldToggle/FieldToggle';

interface SelectColumnButtonFilterProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  resource?: string;
  preferenceKey?: string;
  blockMoveRowName?: string;
}

export const SelectColumnButtonFilter: React.FC<SelectColumnButtonFilterProps> = (props) => {
  const { preferenceKey, blockMoveRowName } = props;

  const resource = useResourceContext(props);
  const finalPreferenceKey = preferenceKey || `${resource}.datagrid`;

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [availableColumns, setAvailableColumns] = useStore<ConfigurableDatagridColumn[]>(
    `preferences.${finalPreferenceKey}.availableColumns`,
    [],
  );

  const [columns, setColumns] = useStore<string[]>(
    `preferences.${finalPreferenceKey}.columns`,
    availableColumns.map((column) => column.index),
  );

  const handleClick = (event: React.MouseEvent<Element>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleToggle = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target?.checked) {
      const filteredColumns = availableColumns
        .filter((column) => column.index === event.target?.name || columns.includes(column.index))
        .map((column) => column.index);
      setColumns(filteredColumns);
    } else {
      setColumns((prev) => prev.filter((index: string) => index !== event.target?.name));
    }
  };

  const handleMove = (arr: string[]): void => {
    const newAvailableColumns = sortColumnsByIndex(availableColumns, arr);
    setAvailableColumns(newAvailableColumns);
    setColumns((columns) =>
      newAvailableColumns
        .filter((column) => columns.includes(column.index))
        .map((column) => column.index),
    );
  };

  return (
    <>
      <Button
        color="secondary"
        label="Колонки"
        onClick={handleClick}
        startIcon={<SettingsIcon />}
      />
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List sx={{ padding: 1 }}>
          {availableColumns.map((column) => {
            // Если в таблице есть колонка с действиями для записи (Action Buttons), то Radmin автоматически индексирует ее как обычную колонку.
            // И т.к. у нее нет имени, он ее назвыет "Безымянный столбец No..." Нам надо исключить возможность вкл/выкл ее и двигать.
            // Но т.к. в компоненте радмина Datagrid уже зашит весь функционал и логика, то переделать ее никак нельзя. Поэтому мы отслеживаем
            // эту колнку по ее имени и запрещаем ее возможность перемещения и делаем ее disabled. Но НЕ отображать мы ее не можем никак, иначе
            // ломается вся логика сортировки колонок и DragAndDrop.
            const serviceRow = column.label?.includes(blockMoveRowName || 'Безымянный');

            return (
              <FieldToggle
                index={column.index}
                key={column.index}
                label={serviceRow ? 'Доступные действия' : column.label}
                onMove={serviceRow ? undefined : handleMove}
                onToggle={serviceRow ? undefined : handleToggle}
                selected={columns.includes(column.index)}
                serviceRow={serviceRow}
                source={column.source}
              />
            );
          })}
        </List>
      </Popover>
    </>
  );
};
