import { Box } from 'shared/mui/Box';
import { Divider } from 'shared/mui/Divider/Divider';
import { Row } from 'shared/mui/Row/Row';
import { TooltipWithInfoIcon } from 'shared/mui/Tooltip';
import { BooleanInput } from 'shared/react-admin/Inputs';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';

import { LabelStyled, LabelWrapperStyled } from './GatewaySettingsForm.styled';

export const GatewaySettingsForm: React.FC = () => {
  return (
    <>
      <Box sx={{ display: 'flex', gap: '24px', flexDirection: 'column' }}>
        <Row>
          <LabelWrapperStyled>
            <Box>Минимальное кол-во заказов</Box>
            <TooltipWithInfoIcon tooltipText="Минимальный порог количества заказов для отправки уведомлений в reddy при регистрации событий по отклонениям" />
          </LabelWrapperStyled>
          <TextInputShrink
            helperText={false}
            key="minOrdersCount"
            label="Значение"
            resettable
            size="medium"
            source="minOrdersCount"
            variant="outlined"
          />
        </Row>
        <Row>
          <LabelWrapperStyled>
            <LabelStyled>Процент отклонения по успешным заказам</LabelStyled>
            <TooltipWithInfoIcon tooltipText="Укажите на сколько единиц, в процентном соотношении, конверсия по заказам может быть ниже среднестатистическо значения" />
          </LabelWrapperStyled>
          <TextInputShrink
            helperText={false}
            key="successDeviation"
            label="Значение (%)"
            resettable
            size="medium"
            source="successDeviation"
            variant="outlined"
          />
        </Row>
        <Row>
          <LabelWrapperStyled>
            <LabelStyled>Процент отклонения по отмененным заказам</LabelStyled>
            <TooltipWithInfoIcon tooltipText="Укажите на сколько единиц, в процентном соотношении, конверсия по заказам может быть выше среднестатистическо значения" />
          </LabelWrapperStyled>
          <TextInputShrink
            helperText={false}
            key="cancelDeviation"
            label="Значение (%)"
            resettable
            size="medium"
            source="cancelDeviation"
            variant="outlined"
          />
        </Row>
        <Row>
          <LabelWrapperStyled>
            <LabelStyled>Процент отклонения по заказам в очереди</LabelStyled>
            <TooltipWithInfoIcon tooltipText="Укажите на сколько единиц, в процентном соотношении, конверсия по заказам может быть выше среднестатистическо значения" />
          </LabelWrapperStyled>
          <TextInputShrink
            helperText={false}
            key="pendingDeviation"
            label="Значение (%)"
            resettable
            size="medium"
            source="pendingDeviation"
            variant="outlined"
          />
        </Row>
      </Box>
      <Divider sx={{ margin: '24px 0' }} />
      <BooleanInput
        label="Включить оповещения для шлюза, при отклонении от установленных пороговых значениях"
        source="notify"
      />
    </>
  );
};
