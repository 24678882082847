import { usePermissions } from 'hooks/usePermissions';
import { useRecordModal } from 'hooks/useRecordModal';
import { useShowController } from 'react-admin';
import { DeleteButton, EditButton } from 'shared/mui/Buttons';
import { CardActionsButtons } from 'shared/mui/Card';
import { ShowPage } from 'shared/react-admin/Pages';
import { CommissionsShowTable } from 'shared/widgets/CommissionsShowTable/CommissionsShowTable';
import { Commission, Permission } from 'types';

import { CommissionsDeleteDialog } from '../components/CommissionsDeleteDialog';

export const CommissionsShow: React.FC = () => {
  const { hasResourcePermissions } = usePermissions();
  const { record } = useShowController();

  const {
    handleOpenRecordModal: handleOpenRecordModalDelete,
    openRecordModal: openRecordModalDelete,
    handleCloseRecordModal: handleCloseRecordModalDelete,
  } = useRecordModal<Commission>();

  const onClose = () => {
    handleCloseRecordModalDelete();
  };

  return (
    <ShowPage
      // additionalActions={[
      //   <Button color="primary" key="btn" label="На согласование" variant="contained" />,
      // ]}
      listName="Комиссии"
      title="Просмотр комиссии"
    >
      <CommissionsShowTable />
      <CardActionsButtons
        leftActionsSlot={
          hasResourcePermissions('commissions', [Permission.Full, Permission.Update]) ? (
            <EditButton />
          ) : undefined
        }
        rightActionsSlot={
          hasResourcePermissions('commissions', [Permission.Full, Permission.Delete]) ? (
            <DeleteButton onClick={() => handleOpenRecordModalDelete()} variant="outlined" />
          ) : undefined
        }
      />
      {openRecordModalDelete && (
        <CommissionsDeleteDialog onClose={onClose} open={openRecordModalDelete} record={record} />
      )}
    </ShowPage>
  );
};
