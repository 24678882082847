import { DeleteStages } from 'constants/deleteStages';

import { useState } from 'react';

import { queryClient } from 'App';
import { useApiContext } from 'context/apiContext';
import { useStagedDelete } from 'hooks/useStagedDelete';
import { FilterPayload } from 'react-admin';
import { Alert } from 'shared/mui/Alert/Alert';
import { CancelButton, CreateButton } from 'shared/mui/Buttons';
import { DeleteStageDialog } from 'shared/mui/Dialogs/DeleteStageDialog/DeleteStageDialog';
import { DeleteStageDialogDenyReason } from 'shared/mui/Dialogs/DeleteStageDialog/DeleteStageDialogDenyReason';
import { ListPage } from 'shared/react-admin/Pages';
import { CompanyShowDialog } from 'shared/widgets/CompanyShowDialog/CompanyShowDialog';
import { cleanEmpty } from 'utils/cleanEmpty';

import { MerchantsListDatagrid } from './MerchantsListDatagrid/MerchantsListDatagrid';
import { DEFAULT_VALUES, MerchantsListFilter } from './MerchantsListFilter/MerchantsListFilter';
import { usePermissions } from '../../../hooks/usePermissions';
import { useRecordModal } from '../../../hooks/useRecordModal';
import { Company, Merchant, Permission } from '../../../types';
import { MerchantsArchiveDialog } from '../components/MerchantsArchiveDialog/MerchantsArchiveDialog';
import { MerchantsUnarchiveDialog } from '../components/MerchantsUnarchiveDialog/MerchantsUnarchiveDialog';

export const MerchantsList: React.FC = () => {
  const [filtersList, setFiltersList] = useState<FilterPayload | undefined>(
    cleanEmpty(DEFAULT_VALUES),
  );
  const { hasResourcePermissions } = usePermissions();
  const {
    record: recordShow,
    handleOpenRecordModal: handleOpenRecordModalShow,
    handleCloseRecordModal: handleCloseRecordModalShow,
    openRecordModal: openRecordModalShow,
  } = useRecordModal<Company>();
  const {
    record: recordDelete,
    handleOpenRecordModal: handleOpenRecordModalDelete,
    openRecordModal: openRecordModalDelete,
    handleCloseRecordModal: handleCloseRecordModalDelete,
  } = useRecordModal<Merchant>();

  const {
    record: recordUnarchive,
    handleOpenRecordModal: handleOpenRecordModalUnarchive,
    openRecordModal: openRecordModalUnarchive,
    handleCloseRecordModal: handleCloseRecordModalUnarchive,
  } = useRecordModal<Merchant>();
  const {
    record: recordArchive,
    handleOpenRecordModal: handleOpenRecordModalArchive,
    openRecordModal: openRecordModalArchive,
    handleCloseRecordModal: handleCloseRecordModalArchive,
  } = useRecordModal<Merchant>();

  const { deleteOneRecord, denyDeleteReason, stage, setStage } = useStagedDelete({
    record: recordDelete,
    onSuccess: async () => {
      await queryClient.refetchQueries(['merchants']);
      handleCloseRecordModalDelete();
      setStage(DeleteStages.Initial);
    },
  });

  const handleCloseDeleteDialog = () => {
    setStage(DeleteStages.Initial);
    handleCloseRecordModalDelete();
  };

  const { user } = useApiContext();

  return (
    <ListPage
      actions={[
        hasResourcePermissions('merchants', [Permission.Full, Permission.Create]) && (
          <CreateButton key="create-merchants" />
        ),
      ]}
      disableSyncWithLocation={true}
      empty={false}
      filter={filtersList}
      headerTitle={{
        titleText: 'Мерчанты',
        tooltipText: 'Учетная запись клиента сервиса Kubera.',
      }}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <MerchantsListFilter setFiltersList={setFiltersList} />
      {user.showAlertEmptyAccess ? (
        <Alert severity="warning">{`Для пользователя ${user.firstName} ${user.lastName} нет доступных мерчантов, обратитесь к администратору системы настроек доступа`}</Alert>
      ) : (
        <MerchantsListDatagrid
          handleOpenRecordModalArchive={handleOpenRecordModalArchive}
          handleOpenRecordModalDelete={handleOpenRecordModalDelete}
          handleOpenRecordModalShow={handleOpenRecordModalShow}
          handleOpenRecordModalUnarchive={handleOpenRecordModalUnarchive}
        />
      )}
      {openRecordModalDelete && (
        <DeleteStageDialog
          deleteOneRecord={deleteOneRecord}
          details={`Мерчант ${recordDelete?.name || ''} будет удален без возможности восстановления.`}
          onClose={handleCloseRecordModalDelete}
          open={openRecordModalDelete}
          stage={stage}
          title="Удаление мерчанта"
        >
          <DeleteStageDialogDenyReason reason={denyDeleteReason}>
            <CancelButton
              key="history-back-button"
              label="Вернуться к списку мерчантов"
              onClick={handleCloseDeleteDialog}
              variant="outlined"
            />
          </DeleteStageDialogDenyReason>
        </DeleteStageDialog>
      )}
      {openRecordModalUnarchive && recordUnarchive && (
        <MerchantsUnarchiveDialog
          onClose={handleCloseRecordModalUnarchive}
          open={openRecordModalUnarchive}
          record={recordUnarchive}
        />
      )}
      {openRecordModalArchive && recordArchive && (
        <MerchantsArchiveDialog
          onClose={handleCloseRecordModalArchive}
          open={openRecordModalArchive}
          record={recordArchive}
        />
      )}
      {recordShow && (
        <CompanyShowDialog
          company={recordShow}
          onClose={handleCloseRecordModalShow}
          open={openRecordModalShow}
        />
      )}
    </ListPage>
  );
};
