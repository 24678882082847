import { Mask } from 'react-text-mask';

export const DEFAULT_DATE_RANGE_MASK: Mask = [
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  '–',
  ' ',
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const DEFAULT_DATE_TIME_RANGE_MASK: Mask = [
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  ':',
  /\d/,
  /\d/,
  ' ',
  '–',
  ' ',
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  ':',
  /\d/,
  /\d/,
];

export const DEFAULT_SEPARATOR = ' – ';

export const DEFAULT_DATE_MASK = 'dd/MM/yyyy';
export const DEFAULT_TIME_MASK = 'HH:mm';
export const DEFAULT_DATE_TIME_MASK = `${DEFAULT_DATE_MASK} ${DEFAULT_TIME_MASK}`;

export const DEFAULT_DATE_RANGE_PLACEHOLDER = `${DEFAULT_DATE_MASK.toUpperCase()}${DEFAULT_SEPARATOR}${DEFAULT_DATE_MASK.toUpperCase()}`;
export const DEFAULT_DATE_TIME_RANGE_PLACEHOLDER = `${DEFAULT_DATE_TIME_MASK.toUpperCase()}${DEFAULT_SEPARATOR}${DEFAULT_DATE_TIME_MASK.toUpperCase()}`;

export const DEFAULT_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
