import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { ChipField } from 'react-admin';

import { CONVERSION_COLORS } from './constants/conversionColors';
import { CommissionStatuses } from '../../../../constants/commissionStatuses';
import { IconButton } from '../../../mui/IconButtons';

export const ChipFieldStyled = styled(ChipField)({
  margin: '0 !important',
  height: '24px',
});

export const StatusIconStyled = styled('div')<{ status?: CommissionStatuses }>(({ status }) => ({
  height: '16px',
  width: '16px',
  backgroundColor: status
    ? CONVERSION_COLORS[status as CommissionStatuses]
    : CONVERSION_COLORS['default'],
  borderRadius: '100%',
}));

export const IconButtonStyled = styled(IconButton)({
  '&.MuiButtonBase-root': {
    marginLeft: '5px',
    backgroundColor: grey[600],
    padding: '3px',
  },
  '& .MuiSvgIcon-root': {
    width: 14,
    height: 14,
    color: '#ffffff',
  },
});
